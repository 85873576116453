import React, { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import translate from "../../../shared/utils/translations";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import EmptyIcon from "../../../../assets/img/empty.png";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";

const FavoritesTable = ({ data, removeFavorite }) => {
  let navigate = useNavigate();
  const [isTableSort, setTableSort] = useState(() => {
    const savedSort = localStorage.getItem("tableSort");
    return savedSort !== null ? savedSort : "list";
  });
  useEffect(() => {
    localStorage.setItem("tableSort", isTableSort);
  }, [isTableSort]);

  const deleteItem = (asin) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.success(translate("Your action was successful!"));
        removeFavorite(asin);
      }
    });
  };

  const columns = [
    {
      title: translate("Image"),
      dataIndex: "image",
      key: "image",
      render: (src) => (
        <>
          <img className="product-photo" src={src} alt="" />
        </>
      ),
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (item) => (
        <>
          <div className="column_info">ASIN</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Title"),
      dataIndex: "title",
      key: "title",
      render: (item) => (
        <>
          <div className="column_info">{translate("Title")}</div>
          {item?.length > 50 ? item.slice(0, 50) + "..." : item}
        </>
      ),
    },
    // {
    //     "id": 67,
    //     "asin": "B09X7FXHVJ",
    //     "user_id": 2,
    //     "title": "SanDisk 128GB Extreme PRO SDXC card, SD Card, V30 Memory Card, 4K UHD, up to 200 MB/s, SanDisk QuickFlow Technology, RescuePro Deluxe Data Recovery Software, UHS-I, Class 10, U3, V30",
    //     "image": "https://m.media-amazon.com/images/I/41MFqNEfeNL.jpg",
    //     "created_at": "2024-08-07T12:36:46.401Z",
    //     "updated_at": "2024-08-07T12:36:46.401Z",
    //     "has_discounts": true,
    //     "base_domain_price": 21.99,
    //     "base_domain_converted_price": 17.29,
    //     "target_domain_price": 19.3,
    //     "shipping_cost": 1.5,
    //     "fee": 5.81,
    //     "roi": -28.23,
    //     "profit_per_item": -5.3,
    //     "is_amazon_seller": "true",
    //     "total_seller_count": 42,
    //     "fba_seller_count": 12,
    //     "fbm_seller_count": 30,
    //     "sales_rank": 26,
    //     "sales_rank_category": "pc",
    //     "brand": "SanDisk",
    //     "binding": "Personal Computer",
    //     "product_group": "Components & Replacement Parts",
    //     "product_type_name": "FLASH_MEMORY",
    //     "item_dimensions": "0.90 x 0.90 x 0.10 inches",
    //     "package_dimensions": "4.02 x 5.83 x 0.55 inches",
    //     "monthly_sold": 5000,
    //     "sales_rank_drops_30": 46,
    //     "sales_rank_drops_90": 164,
    //     "sales_rank_drops_180": 354,
    //     "sales_rank_drops_365": 696,
    //     "preference_id": 67,
    //     "name": "test us-uk",
    //     "base_domain": "www.amazon.com",
    //     "target_domain": "www.amazon.co.uk",
    //     "currency": "GBP",
    //     "shipping_type": "fixed",
    //     "shipping_deal": "1.50",
    //     "labeling_deal": null,
    //     "fixed_deal": "1.50",
    //     "is_fba": true,
    //     "uses_keepa": true,
    //     "include_discounts": true,
    //     "vat_type": "noVat",
    //     "vat_percentage": "19",
    //     "selected": true,
    //     "is_deleted": false
    // }
    {
      title: translate("Source price"),
      dataIndex: "base_domain_converted_price",
      key: "base_domain_converted_price",
      render: (item) => (
        <>
          <div className="column_info">{translate("Source price")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Target price"),
      dataIndex: "target_domain_price",
      key: "target_domain_price",
      render: (item) => (
        <>
          <div className="column_info">{translate("Target price")}</div>
          {item}
        </>
      ),
    },

    {
      title: translate("ROI"),
      dataIndex: "roi",
      key: "roi",
      render: (item) => (
        <>
          <div className="column_info">{translate("ROI")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Is Amazon Selling?"),
      dataIndex: "is_amazon_seller",
      key: "is_amazon_seller",
      render: (item) => (
        <>
          <div className="column_info">{translate("Is Amazon Selling?")}</div>
          {item ? translate("Yes") : translate("No")}
        </>
      ),
    },
    {
      title: translate("Monthly Sold"),
      dataIndex: "monthly_sold",
      key: "monthly_sold",
      render: (item) => (
        <>
          <div className="column_info">{translate("Monthly Sold")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("BSR Drops (30/90/180)"),

      render: (item, record) => {
        console.log("record", record);
        return (
          <>
            {`${record.sales_rank_drops_30}/${record.sales_rank_drops_90}/${record.sales_rank_drops_180}`}
          </>
        );
      },
    },

    {
      title: translate("Total Offer Count"),
      dataIndex: "total_seller_count",
      key: "total_seller_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("Total Offer Count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("FBA Seller Count"),
      dataIndex: "fba_seller_count",
      key: "fba_seller_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("FBA Seller Count")}</div>
          {item}
        </>
      ),
    },

    {
      title: translate("FBM Seller Count"),
      dataIndex: "fbm_seller_count",
      key: "fbm_seller_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("FBM Seller Count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Sales Rank Category"),
      dataIndex: "sales_rank_category",
      key: "sales_rank_category",
      render: (item) => (
        <>
          <div className="column_info">{translate("Sales Rank Category")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Sales Rank"),
      dataIndex: "sales_rank",
      key: "sales_rank",
      render: (item) => (
        <>
          <div className="column_info">{translate("Sales Rank")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Brand"),
      dataIndex: "brand",
      key: "brand",
      render: (item) => (
        <>
          <div className="column_info">{translate("Brand")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Product Group"),
      dataIndex: "product_group",
      key: "product_group",
      render: (item) => (
        <>
          <div className="column_info">{translate("Product Group")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Product Type"),
      dataIndex: "product_type_name",
      key: "product_type_name",
      render: (item) => (
        <>
          <div className="column_info">{translate("Product Type")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Item Dimensions"),
      dataIndex: "item_dimensions",
      key: "item_dimensions",
      render: (item) => (
        <>
          <div className="column_info">{translate("Item Dimensions")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Package Dimensions"),
      dataIndex: "package_dimensions",
      key: "package_dimensions",
      render: (item) => (
        <>
          <div className="column_info">{translate("Package Dimensions")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Preference Name"),
      dataIndex: "name",
      key: "name",
      render: (item) => (
        <>
          <div className="column_info">{translate("Preference Name")}</div>
          {item}
        </>
      ),
    },

    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>

          <div className="flex_table_buttons">
            <Tooltip
              title={translate("Show Product Based Info")}
              placement="left"
            >
              <NavLink
                className={"pbi__link table_icon"}
                to={`/product-based-info/${record.asin}`}
                target="_blank"
              >
                <FeatherIcon icon={"package"} />
              </NavLink>
            </Tooltip>

            <Tooltip title={translate("Delete")} placement="top">
              <Button
                onClick={() => deleteItem(record.asin)}
                icon={<FeatherIcon icon="trash" />}
                className={"table_icon"}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: "1",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534222",
  //     name: "Yellow Couch",
  //     salesRank: 3200,
  //     roi: 25,
  //     tags: ["Good"],
  //   },
  //   {
  //     key: "2",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534000",
  //     name: "Green Couch",
  //     salesRank: 2888,
  //     roi: 25,
  //     tags: ["Good"],
  //   },
  //   {
  //     key: "3",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534654",
  //     name: "Turquoise Seat",
  //     salesRank: 1200,
  //     roi: 25,
  //     tags: ["Good"],
  //   },
  //   {
  //     key: "4",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534877",
  //     name: "Cyan Couch",
  //     salesRank: 8560,
  //     roi: 8,
  //     tags: ["Bad"],
  //   },
  //   {
  //     key: "5",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534342",
  //     name: "Coffee Table",
  //     salesRank: 1150,
  //     roi: 75,
  //     tags: ["Very Good"],
  //   },
  //   {
  //     key: "7",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534456",
  //     name: "Red Couch",
  //     salesRank: 9500,
  //     roi: 5,
  //     tags: ["Bad"],
  //   },
  // ];

  return (
    <>
      <div
        className={`dif__ablee ${isTableSort === "list" ? " " : " block_m"}`}
      >
        <div className="merged___area">
          <div className="table__sortn">
            <span
              onClick={() => setTableSort("list")}
              className={`${isTableSort === "list" ? "active" : ""}`}
            >
              <FeatherIcon icon={"list"} />
            </span>
            <span
              onClick={() => setTableSort("grid")}
              className={`${isTableSort === "grid" ? "active" : ""}`}
            >
              <FeatherIcon icon={"grid"} />
            </span>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["topRight", "bottomRight"],
          }}
          locale={{
            emptyText: (
              <span className="empty__tble">
                <img src={EmptyIcon} />
              </span>
            ),
          }}
          className="mob_bts has_pag_btn"
        />
      </div>
    </>
  );
};

export default FavoritesTable;
