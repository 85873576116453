import React from "react";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";

const DimensionsInfos = ({ additionalData }) => {
  return (
    <>
      <div className="product__dmns_info">
        <img src="/assets/img/icons8-front-view-64.png"></img>
        <div className="dmns_item">
          <h4>{translate("Item Dimensions")}</h4>
          <span>{additionalData?.item_dimensions_cm}</span>
        </div>
        <div className="dmns_item">
          <h4>{translate("Package Dimensions")}</h4>
          <span>{additionalData?.package_dimensions_cm}</span>
        </div>
        <div className="dmns_item">
          <h4>{translate("Item Weight")}</h4>
          <span>{additionalData?.item_weight_kg} Kg</span>
        </div>
        <div className="dmns_item">
          <h4>{translate("Package Weight")}</h4>
          <span>{additionalData?.package_weight_kg} Kg</span>
        </div>
      </div>
    </>
  );
};

export default DimensionsInfos;
