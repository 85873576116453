import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import translate from "../utils/translations";

const Menu = ({ isMenu, setMenu, setMobile }) => {
  const location = useLocation();

  return (
    <div
      className={`header__item header__item_dropdown js-header-item ${
        isMenu && " active"
      }`}
    >
      <button
        className="header__head js-header-head menu_drop_w"
        onClick={() => setMenu(!isMenu)}
      >
        {location.pathname === "/"
          ? translate("Home")
          : location.pathname === "/about"
          ? translate("About us")
          : location.pathname === "/blog"
          ? "Blog"
          : location.pathname === "/support"
          ? translate("Support")
          : location.pathname === "/pricing"
          ? translate("Pricing")
          : location.pathname === "/client-dashboard"
          ? translate("Dashboard")
          : location.pathname === "/admin-dashboard"
          ? translate("Dashboard")
          : location.pathname === "/settings"
          ? translate("Settings")
          : location.pathname === "/my-plan"
          ? translate("My Plan")
          : location.pathname === "/tickets"
          ? translate("Tickets")
          : location.pathname === "/notifications"
          ? translate("Notifications")
          : location.pathname === "/blog-details"
          ? translate("Blog Details")
          : location.pathname === "/terms"
          ? translate("Terms")
          : location.pathname === "/privacy-policy"
          ? translate("Privacy")
          : location.pathname === "/how-it-works"
          ? translate("How It Works")
          : location.pathname === "/personal-settings"
          ? translate("Personal Info")
          : location.pathname === "/security-settings"
          ? translate("Security")
          : location.pathname === "/marketplace-settings"
          ? translate("Marketplace settings")
          : location.pathname === "/integration-settings"
          ? translate("3rd-party Integrations")
          : location.pathname === "/asin360"
          ? translate("Asin 360")
          : location.pathname === "/history"
          ? translate("History")
          : location.pathname === "/favorites"
          ? translate("Favorites")
          : location.pathname === "/keyword-tool"
          ? translate("Keyword Tool")
          : location.pathname === "/product-based-info"
          ? translate("Product Based Info")
          : location.pathname === "/consulting"
          ? translate("Consulting")
          : location.pathname === "/warehousing"
          ? translate("Warehousing")
          : "Menu"}
        <svg className="icon icon-arrow-down">
          <use xlinkHref="#icon-arrow-down"></use>
        </svg>
      </button>
      <div className="header__body js-header-body">
        <div className="header__menu">
          <NavLink
            className="header__link"
            to="/"
            onClick={() => {
              setMenu(false);
              setMobile(false);
            }}
          >
            <svg
              className="icon n__icn icon-home-n"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 15L12 18" strokeWidth="2" strokeLinecap="round" />
              <path
                d="M22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            {translate("Home")}
          </NavLink>
          <NavLink
            className="header__link"
            to="/about"
            onClick={() => {
              setMenu(false);
              setMobile(false);
            }}
          >
            <svg
              className="icon n__icn icon-home-n"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 12C22 15.7713 22 17.6569 20.8284 18.8285C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8285C2 17.6569 2 15.7713 2 12C2 8.22881 2 6.34319 3.17157 5.17162C4.23467 4.10853 5.8857 4.01009 9 4.00098M15 4.00098C18.1143 4.01009 19.7653 4.10853 20.8284 5.17162C21.4816 5.8248 21.7706 6.69994 21.8985 8.00006"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path d="M12 5L12 3" strokeWidth="2" strokeLinecap="round" />
              <path d="M8 10.5H16" strokeWidth="2" strokeLinecap="round" />
              <path d="M8 14H13.5" strokeWidth="2" strokeLinecap="round" />
            </svg>
            {translate("About us")}
          </NavLink>
          <NavLink
            className="header__link"
            to="/warehousing"
            onClick={() => {
              setMenu(false);
              setMobile(false);
            }}
          >
            <svg
              className="icon n__icn icon-home-n"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.9844 10C21.9473 8.68893 21.8226 7.85305 21.4026 7.13974C20.8052 6.12523 19.7294 5.56066 17.5777 4.43152L15.5777 3.38197C13.8221 2.46066 12.9443 2 12 2C11.0557 2 10.1779 2.46066 8.42229 3.38197L6.42229 4.43152C4.27063 5.56066 3.19479 6.12523 2.5974 7.13974C2 8.15425 2 9.41667 2 11.9415V12.0585C2 14.5833 2 15.8458 2.5974 16.8603C3.19479 17.8748 4.27063 18.4393 6.42229 19.5685L8.42229 20.618C10.1779 21.5393 11.0557 22 12 22C12.9443 22 13.8221 21.5393 15.5777 20.618L17.5777 19.5685C19.7294 18.4393 20.8052 17.8748 21.4026 16.8603C21.8226 16.1469 21.9473 15.3111 21.9844 14"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M21 7.5L17 9.5M12 12L3 7.5M12 12V21.5M12 12C12 12 14.7426 10.6287 16.5 9.75C16.6953 9.65237 17 9.5 17 9.5M17 9.5V13M17 9.5L7.5 4.5"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            {translate("Warehousing")}
          </NavLink>
          <NavLink
            className="header__link"
            to="/consulting"
            onClick={() => {
              setMenu(false);
              setMobile(false);
            }}
          >
            <svg
              className="icon n__icn icon-home-n"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="4" stroke-width="2" />
              <path d="M15 9L19 5" stroke-width="2" />
              <path d="M5 19L9 15" stroke-width="2" />
              <path d="M9 9L5 5" stroke-width="2" />
              <path d="M19 19L15 15" stroke-width="2" />
              <path
                d="M9.41235 2.33892C11.0533 1.89775 12.8289 1.86936 14.5882 2.34078C19.9229 3.7702 23.0887 9.25357 21.6593 14.5882C20.2299 19.9229 14.7465 23.0887 9.41185 21.6593C4.07719 20.2299 0.911364 14.7465 2.34078 9.41185C2.8122 7.65248 3.72457 6.12901 4.92711 4.92847"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            {translate("Consulting")}
          </NavLink>
          <NavLink
            className="header__link"
            to="/pricing"
            onClick={() => {
              setMenu(false);
              setMobile(false);
            }}
          >
            <svg
              className="icon n__icn icon-home-n"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18" cy="12" r="1" fill="#1C274C" />
              <path
                d="M22 10.5C22 10.4226 22 9.96726 21.9977 9.9346C21.9623 9.43384 21.5328 9.03496 20.9935 9.00214C20.9583 9 20.9167 9 20.8333 9H18.2308C16.4465 9 15 10.3431 15 12C15 13.6569 16.4465 15 18.2308 15H20.8333C20.9167 15 20.9583 15 20.9935 14.9979C21.5328 14.965 21.9623 14.5662 21.9977 14.0654C22 14.0327 21.9977 13.5774 21.9977 13.5"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M13 4C16.7712 4 18.6569 4 19.8284 5.17157C20.6366 5.97975 20.8873 7.1277 20.965 9M10 20H13C16.7712 20 18.6569 20 19.8284 18.8284C20.6366 18.0203 20.8873 16.8723 20.965 15M9 4.00093C5.8857 4.01004 4.23467 4.10848 3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C3.82475 19.4816 4.69989 19.7706 6 19.8985"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M7 9L7 10M7 15L7 12.5"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            {translate("Pricing")}
          </NavLink>
          <NavLink
            className="header__link"
            to="/blog"
            onClick={() => {
              setMenu(false);
              setMobile(false);
            }}
          >
            <svg
              className="icon n__icn icon-home-n"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.55078 4.5C2.61472 3.84994 2.75923 3.41238 3.08582 3.08579C3.67161 2.5 4.61442 2.5 6.50004 2.5C8.38565 2.5 9.32846 2.5 9.91425 3.08579C10.5 3.67157 10.5 4.61438 10.5 6.5C10.5 8.38562 10.5 9.32843 9.91425 9.91421C9.32846 10.5 8.38565 10.5 6.50004 10.5C4.61442 10.5 3.67161 10.5 3.08582 9.91421C2.77645 9.60484 2.63047 9.19589 2.56158 8.60106"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M21.4493 15.5C21.3853 14.8499 21.2408 14.4124 20.9142 14.0858C20.3284 13.5 19.3856 13.5 17.5 13.5C15.6144 13.5 14.6716 13.5 14.0858 14.0858C13.5 14.6716 13.5 15.6144 13.5 17.5C13.5 19.3856 13.5 20.3284 14.0858 20.9142C14.6716 21.5 15.6144 21.5 17.5 21.5C19.3856 21.5 20.3284 21.5 20.9142 20.9142C21.2408 20.5876 21.3853 20.1501 21.4493 19.5"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M2.5 17.5C2.5 15.6144 2.5 14.6716 3.08579 14.0858C3.67157 13.5 4.61438 13.5 6.5 13.5C8.38562 13.5 9.32843 13.5 9.91421 14.0858C10.5 14.6716 10.5 15.6144 10.5 17.5C10.5 19.3856 10.5 20.3284 9.91421 20.9142C9.32843 21.5 8.38562 21.5 6.5 21.5C4.61438 21.5 3.67157 21.5 3.08579 20.9142C2.5 20.3284 2.5 19.3856 2.5 17.5Z"
                strokeWidth="2"
              />
              <path
                d="M13.5 6.5C13.5 4.61438 13.5 3.67157 14.0858 3.08579C14.6716 2.5 15.6144 2.5 17.5 2.5C19.3856 2.5 20.3284 2.5 20.9142 3.08579C21.5 3.67157 21.5 4.61438 21.5 6.5C21.5 8.38562 21.5 9.32843 20.9142 9.91421C20.3284 10.5 19.3856 10.5 17.5 10.5C15.6144 10.5 14.6716 10.5 14.0858 9.91421C13.5 9.32843 13.5 8.38562 13.5 6.5Z"
                strokeWidth="2"
              />
            </svg>
            Blog
          </NavLink>
          <NavLink
            className="header__link"
            to="/support"
            onClick={() => {
              setMenu(false);
              setMobile(false);
            }}
          >
            <svg
              className="icon n__icn icon-home-n"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 19.5H14M10.6667 22H13.3333"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M15 2.60222C14.0907 2.21646 13.0736 2 12 2C8.13401 2 5 4.8069 5 8.21807L5.00007 8.30193C5.00875 10.2984 5.86939 12.2187 7.41058 13.6805L8.51463 14.7196C8.82437 15.0112 9 15.4177 9 15.843C9 16.482 9.518 17 10.157 17H13.843C14.482 17 15 16.482 15 15.843C15 15.4177 15.1756 15.0112 15.4854 14.7196L16.5894 13.6805C18.1306 12.2187 18.9912 10.2984 18.9999 8.30193L19 8.21807C19 7.43838 18.8363 6.69025 18.5375 6"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            {translate("Support")}
          </NavLink>
          <NavLink
            className="header__link"
            to="/how-it-works"
            onClick={() => {
              setMenu(false);
              setMobile(false);
            }}
          >
            <svg
              className="icon n__icn icon-home-n"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.024 14.5601C10.7142 15.484 9.5593 15.946 8.89964 15.4977C8.74324 15.3914 8.60834 15.2565 8.50206 15.1001C8.0538 14.4405 8.51575 13.2856 9.43967 10.9758C9.63673 10.4831 9.73527 10.2368 9.90474 10.0435C9.94792 9.99429 9.99429 9.94792 10.0435 9.90474C10.2368 9.73527 10.4831 9.63673 10.9758 9.43966C13.2856 8.51575 14.4405 8.0538 15.1001 8.50206C15.2565 8.60834 15.3914 8.74324 15.4977 8.89964C15.946 9.5593 15.484 10.7142 14.5601 13.024C14.363 13.5166 14.2645 13.763 14.095 13.9562C14.0518 14.0055 14.0055 14.0518 13.9562 14.095C13.763 14.2645 13.5166 14.363 13.024 14.5601Z"
                strokeWidth="2"
              />
              <path
                d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            {translate("How It Works")}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Menu;
