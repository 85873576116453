import React from "react";
import { Button, Drawer, Tooltip } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import ComparisonTable from "./_comparison_table";
import FeatherIcon from "feather-icons-react";
import Swal from "sweetalert2";
import translate from "../../../../shared/utils/translations";
import { Line, Circle } from "rc-progress";
import { useEffect } from "react";
import { downloadAsinListExcel } from "../../../../shared/utils/helpers";

const ComparsionDrawer = ({
  isComparisonDrawer,
  setComparisonDrawer,
  selectedAsinList,
  restartComparisonProcess,
  getAsinListAsins,
  startComparisonProcess,
  stopComparisonProcess,
}) => {
  const [filterValues, setFilterValues] = useState({});
  const [isProcess, setProcess] = useState(0);
  const [isStatus, setStatus] = useState("Processing");
  const [asinListData, setAsinListData] = useState([]);
  const [bufferAsinListData, setBufferAsinListData] = useState([]);
  const [showStockError, setShowStockError] = useState(false);
  //  it will be like that [
  //     {
  //       name:'roi',
  //       min: 0,
  //       max: 100,
  //     }
  //   ]
  const [selectedFilters, setSelectedFilters] = useState([{}]);

  const handleFilters = (data) => {
    if (!showStockError) {
      return data?.filter((i) => i.stock_error === false && i.roi);
    } else {
      return data;
    }
  };

  const getData = () => {
    getAsinListAsins(selectedAsinList).then((res) => {
      setAsinListData(res);
      setBufferAsinListData(handleFilters(res?.asins));
      setProcess(
        parseFloat(
          (res.asins.filter((i) => i.calculated).length / res.asins.length) *
            100
        ).toFixed(2)
      );
      setStatus(res?.asinList?.status);
    });
  };

  const toggleStockError = () => {
    setShowStockError(!showStockError);
  };
  const downloadItems = async (id, name) => {
    const asinData = await getAsinListAsins(id);
    console.log(asinData);
    downloadAsinListExcel(asinData.asins, name);
    toast.success(translate("Asin list download started!"));
  };
  useEffect(() => {
    if (showStockError) {
      setBufferAsinListData(asinListData?.asins);
    } else {
      setBufferAsinListData(handleFilters(asinListData?.asins));
    }
  }, [showStockError]);

  useEffect(() => {
    if (selectedAsinList && !isNaN(selectedAsinList)) {
      getData();
    }
  }, [selectedAsinList]);

  useEffect(() => {
    console.log(asinListData);
  }, [asinListData]);

  const resetFilters = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setFilterValues({});
        toast.success(translate("All Filters cleared!"));
      }
    });
  };
  const reCalculate = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate(
        "Are you sure you want to recalculate the comparison list?"
      ),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.success(translate("Recalculation started!"));
        restartComparisonProcess(selectedAsinList).then((res) => {
          getData();
        });
      }
    });
  };

  return (
    <>
      <Drawer
        title={
          <div className="cmp_drw_title">
            <h4>{translate("Compare Asins")}</h4>{" "}
            <div className="rght_itms">
              <button
                onClick={() => {
                  getData();
                }}
                className="button-stroke button-small activity__button js-activity-button cc link tt"
              >
                <span>{translate("Refresh")}</span>
              </button>
              {asinListData?.asinList?.status !== "processing" ? (
                <button
                  onClick={() => {
                    startComparisonProcess(selectedAsinList).then((res) => {
                      getData();
                    });
                  }}
                  className="button-stroke button-small activity__button js-activity-button cc link tt"
                >
                  <span>{translate("Start")}</span>
                </button>
              ) : (
                <button
                  onClick={() => {
                    stopComparisonProcess(selectedAsinList).then((res) => {
                      getData();
                    });
                  }}
                  className="button-stroke button-small activity__button js-activity-button cc link tt"
                >
                  <span>{translate("Stop")}</span>
                </button>
              )}
            </div>
          </div>
        }
        placement="right"
        onClose={() => setComparisonDrawer(false)}
        open={isComparisonDrawer}
        width={"100%"}
        className="cmp__drwe"
      >
        <>
          <div className="comparsion__hdr">
            <div className="cmp_lft">
              <div className="sts_cmp">
                <div className="kstatus__area">
                  <div className="kstatus_infs">
                    <div className={`sts ${isStatus}`}>
                      {isStatus === "Processing" ? (
                        <div className="loader_v2">
                          <div className="bar1"></div>
                          <div className="bar2"></div>
                          <div className="bar3"></div>
                          <div className="bar4"></div>
                          <div className="bar5"></div>
                          <div className="bar6"></div>
                          <div className="bar7"></div>
                          <div className="bar8"></div>
                          <div className="bar9"></div>
                          <div className="bar10"></div>
                          <div className="bar11"></div>
                          <div className="bar12"></div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {isStatus}
                    </div>
                    <span>{isProcess}%</span>
                  </div>
                  <Line
                    percent={isProcess}
                    strokeWidth={2}
                    strokeColor={`${
                      isStatus === "completed"
                        ? "#6ab04c"
                        : isStatus === "stopped"
                        ? "#eb4d4b"
                        : isStatus === "processing"
                        ? "#22a6b3"
                        : isStatus === "queued"
                        ? "#f9ca24"
                        : " "
                    }`}
                  />
                </div>
              </div>
              <div className="cmp_inf">
                <h5>{translate("Total Asin Count")}</h5>
                <span>{asinListData?.asins?.length}</span>
              </div>
              <div className="cmp_inf">
                <h5>{translate("Calculated Asin Count")}</h5>
                <span>
                  {asinListData?.asins?.filter((i) => i.calculated).length}
                </span>
              </div>
              <div className="cmp_inf">
                <h5>{translate("Stock Error Count")}</h5>
                <span>
                  {
                    asinListData?.asins?.filter((i) => i.stock_error || !i.roi)
                      .length
                  }
                </span>
              </div>
              <div className="cmp_inf">
                <h5>{translate("Filtered Asin Count")}</h5>
                <span>0</span>
              </div>
              <div className="cmp_inf">
                <h5>{translate("Comparison Tokens")}</h5>
                <span>{asinListData?.token_count}</span>
              </div>
            </div>
            <div className="cmp_rght">
              <div className="toggle_kpp">
                <span>{translate("Stock errors")}</span>
                <label className="switch_kpp">
                  <input
                    type="checkbox"
                    checked={showStockError}
                    onChange={toggleStockError}
                  />
                  <span className="slider_kpp"></span>
                </label>
              </div>
              <button
                onClick={resetFilters}
                className="button-stroke button-small activity__button js-activity-button cc link"
              >
                <span>
                  <FeatherIcon icon={"filter"} /> {translate("Reset Filters")}
                </span>
              </button>

              <div className="btn_grp">
                <button
                  onClick={() => {
                    downloadItems(
                      selectedAsinList,
                      asinListData?.asinList?.name
                    );
                  }}
                  className="button-stroke button-small activity__button js-activity-button cc"
                >
                  <span>
                    <FeatherIcon icon={"download"} /> {translate("Download")}
                  </span>
                </button>
                <Tooltip title={translate("Re-calculate")} placement="top">
                  <button
                    onClick={reCalculate}
                    className="button-stroke button-small activity__button js-activity-button cc icn"
                  >
                    <span>
                      <FeatherIcon icon={"repeat"} />
                    </span>
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>

          <ComparisonTable
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            dataSource={bufferAsinListData}
            data={bufferAsinListData}
            setBufferAsinListData={setBufferAsinListData}
            asinListData={asinListData?.asinList}
          />
        </>
      </Drawer>
    </>
  );
};

export default ComparsionDrawer;
