import { notification } from "antd";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import UserContext from "./userContext";
import translate from "../utils/translations";
const ExcelJS = require("exceljs");

export const passwordChecker = (pass1, pass2) => {
  //passwors should match, and be at least 8 characters long, and contain at least one number, one lowercase

  if (pass1?.length < 8) {
    return translate("Password must be at least 8 characters long!");
  }
  if (pass1 !== pass2) {
    return translate("Passwords must match");
  }
  if (!/\d/.test(pass1)) {
    return translate("Password must contain at least one number!");
  }
  if (!/[a-z]/.test(pass1)) {
    return translate("Password must contain at least one lowercase letter!");
  }
  return true;
};
export const emailChecker = (email) => {
  //passwors should match, and be at least 8 characters long, and contain at least one number, one lowercase
  if (!email) return translate("Please enter an email address!");
  if (!email.includes("@")) {
    return translate("Please enter a valid email address!");
  }

  return true;
};
export const checkIfInputIsAsin = (input) => {
  const regex = /^[A-Z0-9]{10}$/;
  return regex.test(input);
};
export const notify = (message, type) =>
  type === "error" ? toast.error(message) : toast.success(message);
export const availableDomains = [
  {
    domain: "www.amazon.com",
    currency: "USD",
    country: "US",
    symbol: "$",
    id: "ATVPDKIKX0DER",
    server: "na",
  },
  {
    domain: "www.amazon.ca",
    currency: "CAD",
    country: "CA",
    symbol: "$",
    id: "A2EUQ1WTGCTBG2",
    server: "na",
  },
  {
    domain: "www.amazon.com.mx",
    currency: "MXN",
    country: "MX",
    symbol: "$",
    id: "A1AM78C64UM0Y8",
    server: "na",
  },
  {
    domain: "www.amazon.co.uk",
    currency: "GBP",
    country: "GB",
    symbol: "£",
    id: "A1F83G8C2ARO7P",
    server: "eu",
  },
  {
    domain: "www.amazon.de",
    currency: "EUR",
    country: "DE",
    symbol: "€",
    id: "A1PA6795UKMFR9",
    server: "eu",
  },
  {
    domain: "www.amazon.fr",
    currency: "EUR",
    country: "FR",
    symbol: "€",
    id: "A13V1IB3VIYZZH",
    server: "eu",
  },
  {
    domain: "www.amazon.it",
    currency: "EUR",
    country: "IT",
    symbol: "€",
    id: "APJ6JRA9NG5V4",
    server: "eu",
  },
  {
    domain: "www.amazon.es",
    currency: "EUR",
    country: "ES",
    symbol: "€",
    id: "A1RKKUPIHCS9HS",
    server: "eu",
  },
  {
    domain: "www.amazon.co.jp",
    currency: "JPY",
    country: "JP",
    symbol: "¥",
    id: "A1VC38T7YXB528",
    server: "fe",
  },
  {
    domain: "www.amazon.sg",
    currency: "SGD",
    country: "SG",
    symbol: "$",
    id: "A19VAU5U5O7RUS",
    server: "fe",
  },
  {
    domain: "www.amazon.ae",
    currency: "AED",
    country: "AE",
    symbol: "د.إ",
    id: "A2VIGQ35RCS4UG",
    server: "eu",
  },
  {
    domain: "www.amazon.com.br",
    currency: "BRL",
    country: "BR",
    symbol: "R$",
    id: "A2Q3Y263D00KWC",
    server: "na",
  },
  {
    domain: "www.amazon.com.au",
    currency: "AUD",
    country: "AU",
    symbol: "$",
    id: "A39IBJ37TRP1C6",
    server: "fe",
  },
  {
    domain: "www.amazon.in",
    currency: "INR",
    country: "IN",
    symbol: "₹",
    id: "A21TJRUUN4KGV",
    server: "eu",
  },
  {
    domain: "www.amazon.nl",
    currency: "EUR",
    country: "NL",
    symbol: "€",
    id: "A1805IZSGTT6HS",
    server: "eu",
  },
  {
    domain: "www.amazon.sa",
    currency: "SAR",
    country: "SA",
    symbol: "ر.س",
    id: "A17E79C6D8DWNP",
    server: "eu",
  },
  {
    domain: "www.amazon.com.tr",
    currency: "TRY",
    country: "TR",
    symbol: "₺",
    id: "A33AVAJ2PDY3EV",
    server: "eu",
  },
  {
    domain: "www.amazon.se",
    currency: "SEK",
    country: "SE",
    symbol: "kr",
    id: "A2NODRKZP88ZB9",
    server: "eu",
  },
  {
    domain: "www.amazon.com.be",
    currency: "EUR",
    country: "BE",
    symbol: "€",
    id: "AMEN7PMS3EDWL",
    server: "eu",
  },
  {
    domain: "www.amazon.eg",
    currency: "EGP",
    country: "EG",
    symbol: "ج.م",
    id: "ARBP9OOSHTCHU",
    server: "eu",
  },
  {
    domain: "www.amazon.pl",
    currency: "PLN",
    country: "PL",
    symbol: "zł",
    id: "A1C3SOZRARQ6R3",
    server: "eu",
  },
];
export const countryCodes = [
  {
    code: "TR",
    name: "Türkiye",
  },
  {
    code: "US",
    name: "United States of America",
  },
  {
    code: "AD",
    name: "Andorra",
  },
  {
    code: "AE",
    name: "United Arab Emirates",
  },
  {
    code: "AF",
    name: "Afghanistan",
  },
  {
    code: "AG",
    name: "Antigua and Barbuda",
  },
  {
    code: "AI",
    name: "Anguilla",
  },
  {
    code: "AL",
    name: "Albania",
  },
  {
    code: "AM",
    name: "Armenia",
  },
  {
    code: "AO",
    name: "Angola",
  },
  {
    code: "AQ",
    name: "Antarctica",
  },
  {
    code: "AR",
    name: "Argentina",
  },
  {
    code: "AS",
    name: "American Samoa",
  },
  {
    code: "AT",
    name: "Austria",
  },
  {
    code: "AU",
    name: "Australia",
  },
  {
    code: "AW",
    name: "Aruba",
  },
  {
    code: "AX",
    name: "Åland Islands",
  },
  {
    code: "AZ",
    name: "Azerbaijan",
  },
  {
    code: "BA",
    name: "Bosnia and Herzegovina",
  },
  {
    code: "BB",
    name: "Barbados",
  },
  {
    code: "BD",
    name: "Bangladesh",
  },
  {
    code: "BE",
    name: "Belgium",
  },
  {
    code: "BF",
    name: "Burkina Faso",
  },
  {
    code: "BG",
    name: "Bulgaria",
  },
  {
    code: "BH",
    name: "Bahrain",
  },
  {
    code: "BI",
    name: "Burundi",
  },
  {
    code: "BJ",
    name: "Benin",
  },
  {
    code: "BL",
    name: "Saint Barthélemy",
  },
  {
    code: "BM",
    name: "Bermuda",
  },
  {
    code: "BN",
    name: "Brunei Darussalam",
  },
  {
    code: "BO",
    name: "Bolivia, Plurinational State of",
  },
  {
    code: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
  },
  {
    code: "BR",
    name: "Brazil",
  },
  {
    code: "BS",
    name: "Bahamas",
  },
  {
    code: "BT",
    name: "Bhutan",
  },
  {
    code: "BV",
    name: "Bouvet Island",
  },
  {
    code: "BW",
    name: "Botswana",
  },
  {
    code: "BY",
    name: "Belarus",
  },
  {
    code: "BZ",
    name: "Belize",
  },
  {
    code: "CA",
    name: "Canada",
  },
  {
    code: "CC",
    name: "Cocos (Keeling) Islands",
  },
  {
    code: "CD",
    name: "Congo, Democratic Republic of the",
  },
  {
    code: "CF",
    name: "Central African Republic",
  },
  {
    code: "CG",
    name: "Congo",
  },
  {
    code: "CH",
    name: "Switzerland",
  },
  {
    code: "CI",
    name: "Côte d'Ivoire",
  },
  {
    code: "CK",
    name: "Cook Islands",
  },
  {
    code: "CL",
    name: "Chile",
  },
  {
    code: "CM",
    name: "Cameroon",
  },
  {
    code: "CN",
    name: "China",
  },
  {
    code: "CO",
    name: "Colombia",
  },
  {
    code: "CR",
    name: "Costa Rica",
  },
  {
    code: "CU",
    name: "Cuba",
  },
  {
    code: "CV",
    name: "Cabo Verde",
  },
  {
    code: "CW",
    name: "Curaçao",
  },
  {
    code: "CX",
    name: "Christmas Island",
  },
  {
    code: "CY",
    name: "Cyprus",
  },
  {
    code: "CZ",
    name: "Czechia",
  },
  {
    code: "DE",
    name: "Germany",
  },
  {
    code: "DJ",
    name: "Djibouti",
  },
  {
    code: "DK",
    name: "Denmark",
  },
  {
    code: "DM",
    name: "Dominica",
  },
  {
    code: "DO",
    name: "Dominican Republic",
  },
  {
    code: "DZ",
    name: "Algeria",
  },
  {
    code: "EC",
    name: "Ecuador",
  },
  {
    code: "EE",
    name: "Estonia",
  },
  {
    code: "EG",
    name: "Egypt",
  },
  {
    code: "EH",
    name: "Western Sahara",
  },
  {
    code: "ER",
    name: "Eritrea",
  },
  {
    code: "ES",
    name: "Spain",
  },
  {
    code: "ET",
    name: "Ethiopia",
  },
  {
    code: "FI",
    name: "Finland",
  },
  {
    code: "FJ",
    name: "Fiji",
  },
  {
    code: "FK",
    name: "Falkland Islands (Malvinas)",
  },
  {
    code: "FM",
    name: "Micronesia, Federated States of",
  },
  {
    code: "FO",
    name: "Faroe Islands",
  },
  {
    code: "FR",
    name: "France",
  },
  {
    code: "GA",
    name: "Gabon",
  },
  {
    code: "GB",
    name: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    code: "GD",
    name: "Grenada",
  },
  {
    code: "GE",
    name: "Georgia",
  },
  {
    code: "GF",
    name: "French Guiana",
  },
  {
    code: "GG",
    name: "Guernsey",
  },
  {
    code: "GH",
    name: "Ghana",
  },
  {
    code: "GI",
    name: "Gibraltar",
  },
  {
    code: "GL",
    name: "Greenland",
  },
  {
    code: "GM",
    name: "Gambia",
  },
  {
    code: "GN",
    name: "Guinea",
  },
  {
    code: "GP",
    name: "Guadeloupe",
  },
  {
    code: "GQ",
    name: "Equatorial Guinea",
  },
  {
    code: "GR",
    name: "Greece",
  },
  {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "GT",
    name: "Guatemala",
  },
  {
    code: "GU",
    name: "Guam",
  },
  {
    code: "GW",
    name: "Guinea-Bissau",
  },
  {
    code: "GY",
    name: "Guyana",
  },
  {
    code: "HK",
    name: "Hong Kong",
  },
  {
    code: "HM",
    name: "Heard Island and McDonald Islands",
  },
  {
    code: "HN",
    name: "Honduras",
  },
  {
    code: "HR",
    name: "Croatia",
  },
  {
    code: "HT",
    name: "Haiti",
  },
  {
    code: "HU",
    name: "Hungary",
  },
  {
    code: "ID",
    name: "Indonesia",
  },
  {
    code: "IE",
    name: "Ireland",
  },
  {
    code: "IL",
    name: "Israel",
  },
  {
    code: "IM",
    name: "Isle of Man",
  },
  {
    code: "IN",
    name: "India",
  },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    code: "IQ",
    name: "Iraq",
  },
  {
    code: "IR",
    name: "Iran, Islamic Republic of",
  },
  {
    code: "IS",
    name: "Iceland",
  },
  {
    code: "IT",
    name: "Italy",
  },
  {
    code: "JE",
    name: "Jersey",
  },
  {
    code: "JM",
    name: "Jamaica",
  },
  {
    code: "JO",
    name: "Jordan",
  },
  {
    code: "JP",
    name: "Japan",
  },
  {
    code: "KE",
    name: "Kenya",
  },
  {
    code: "KG",
    name: "Kyrgyzstan",
  },
  {
    code: "KH",
    name: "Cambodia",
  },
  {
    code: "KI",
    name: "Kiribati",
  },
  {
    code: "KM",
    name: "Comoros",
  },
  {
    code: "KN",
    name: "Saint Kitts and Nevis",
  },
  {
    code: "KP",
    name: "Korea, Democratic People's Republic of",
  },
  {
    code: "KR",
    name: "Korea, Republic of",
  },
  {
    code: "KW",
    name: "Kuwait",
  },
  {
    code: "KY",
    name: "Cayman Islands",
  },
  {
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    code: "LA",
    name: "Lao People's Democratic Republic",
  },
  {
    code: "LB",
    name: "Lebanon",
  },
  {
    code: "LC",
    name: "Saint Lucia",
  },
  {
    code: "LI",
    name: "Liechtenstein",
  },
  {
    code: "LK",
    name: "Sri Lanka",
  },
  {
    code: "LR",
    name: "Liberia",
  },
  {
    code: "LS",
    name: "Lesotho",
  },
  {
    code: "LT",
    name: "Lithuania",
  },
  {
    code: "LU",
    name: "Luxembourg",
  },
  {
    code: "LV",
    name: "Latvia",
  },
  {
    code: "LY",
    name: "Libya",
  },
  {
    code: "MA",
    name: "Morocco",
  },
  {
    code: "MC",
    name: "Monaco",
  },
  {
    code: "MD",
    name: "Moldova, Republic of",
  },
  {
    code: "ME",
    name: "Montenegro",
  },
  {
    code: "MF",
    name: "Saint Martin (French part)",
  },
  {
    code: "MG",
    name: "Madagascar",
  },
  {
    code: "MH",
    name: "Marshall Islands",
  },
  {
    code: "MK",
    name: "North Macedonia",
  },
  {
    code: "ML",
    name: "Mali",
  },
  {
    code: "MM",
    name: "Myanmar",
  },
  {
    code: "MN",
    name: "Mongolia",
  },
  {
    code: "MO",
    name: "Macao",
  },
  {
    code: "MP",
    name: "Northern Mariana Islands",
  },
  {
    code: "MQ",
    name: "Martinique",
  },
  {
    code: "MR",
    name: "Mauritania",
  },
  {
    code: "MS",
    name: "Montserrat",
  },
  {
    code: "MT",
    name: "Malta",
  },
  {
    code: "MU",
    name: "Mauritius",
  },
  {
    code: "MV",
    name: "Maldives",
  },
  {
    code: "MW",
    name: "Malawi",
  },
  {
    code: "MX",
    name: "Mexico",
  },
  {
    code: "MY",
    name: "Malaysia",
  },
  {
    code: "MZ",
    name: "Mozambique",
  },
  {
    code: "NA",
    name: "Namibia",
  },
  {
    code: "NC",
    name: "New Caledonia",
  },
  {
    code: "NE",
    name: "Niger",
  },
  {
    code: "NF",
    name: "Norfolk Island",
  },
  {
    code: "NG",
    name: "Nigeria",
  },
  {
    code: "NI",
    name: "Nicaragua",
  },
  {
    code: "NL",
    name: "Netherlands, Kingdom of the",
  },
  {
    code: "NO",
    name: "Norway",
  },
  {
    code: "NP",
    name: "Nepal",
  },
  {
    code: "NR",
    name: "Nauru",
  },
  {
    code: "NU",
    name: "Niue",
  },
  {
    code: "NZ",
    name: "New Zealand",
  },
  {
    code: "OM",
    name: "Oman",
  },
  {
    code: "PA",
    name: "Panama",
  },
  {
    code: "PE",
    name: "Peru",
  },
  {
    code: "PF",
    name: "French Polynesia",
  },
  {
    code: "PG",
    name: "Papua New Guinea",
  },
  {
    code: "PH",
    name: "Philippines",
  },
  {
    code: "PK",
    name: "Pakistan",
  },
  {
    code: "PL",
    name: "Poland",
  },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
  },
  {
    code: "PN",
    name: "Pitcairn",
  },
  {
    code: "PR",
    name: "Puerto Rico",
  },
  {
    code: "PS",
    name: "Palestine, State of",
  },
  {
    code: "PT",
    name: "Portugal",
  },
  {
    code: "PW",
    name: "Palau",
  },
  {
    code: "PY",
    name: "Paraguay",
  },
  {
    code: "QA",
    name: "Qatar",
  },
  {
    code: "RE",
    name: "Réunion",
  },
  {
    code: "RO",
    name: "Romania",
  },
  {
    code: "RS",
    name: "Serbia",
  },
  {
    code: "RU",
    name: "Russian Federation",
  },
  {
    code: "RW",
    name: "Rwanda",
  },
  {
    code: "SA",
    name: "Saudi Arabia",
  },
  {
    code: "SB",
    name: "Solomon Islands",
  },
  {
    code: "SC",
    name: "Seychelles",
  },
  {
    code: "SD",
    name: "Sudan",
  },
  {
    code: "SE",
    name: "Sweden",
  },
  {
    code: "SG",
    name: "Singapore",
  },
  {
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    code: "SI",
    name: "Slovenia",
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
  },
  {
    code: "SK",
    name: "Slovakia",
  },
  {
    code: "SL",
    name: "Sierra Leone",
  },
  {
    code: "SM",
    name: "San Marino",
  },
  {
    code: "SN",
    name: "Senegal",
  },
  {
    code: "SO",
    name: "Somalia",
  },
  {
    code: "SR",
    name: "Suriname",
  },
  {
    code: "SS",
    name: "South Sudan",
  },
  {
    code: "ST",
    name: "Sao Tome and Principe",
  },
  {
    code: "SV",
    name: "El Salvador",
  },
  {
    code: "SX",
    name: "Sint Maarten (Dutch part)",
  },
  {
    code: "SY",
    name: "Syrian Arab Republic",
  },
  {
    code: "SZ",
    name: "Eswatini",
  },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
  },
  {
    code: "TD",
    name: "Chad",
  },
  {
    code: "TF",
    name: "French Southern Territories",
  },
  {
    code: "TG",
    name: "Togo",
  },
  {
    code: "TH",
    name: "Thailand",
  },
  {
    code: "TJ",
    name: "Tajikistan",
  },
  {
    code: "TK",
    name: "Tokelau",
  },
  {
    code: "TL",
    name: "Timor-Leste",
  },
  {
    code: "TM",
    name: "Turkmenistan",
  },
  {
    code: "TN",
    name: "Tunisia",
  },
  {
    code: "TO",
    name: "Tonga",
  },

  {
    code: "TT",
    name: "Trinidad and Tobago",
  },
  {
    code: "TV",
    name: "Tuvalu",
  },
  {
    code: "TW",
    name: "Taiwan, Province of China",
  },
  {
    code: "TZ",
    name: "Tanzania, United Republic of",
  },
  {
    code: "UA",
    name: "Ukraine",
  },
  {
    code: "UG",
    name: "Uganda",
  },
  {
    code: "UM",
    name: "United States Minor Outlying Islands",
  },

  {
    code: "UY",
    name: "Uruguay",
  },
  {
    code: "UZ",
    name: "Uzbekistan",
  },
  {
    code: "VA",
    name: "Holy See",
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
  },
  {
    code: "VE",
    name: "Venezuela, Bolivarian Republic of",
  },
  {
    code: "VG",
    name: "Virgin Islands (British)",
  },
  {
    code: "VI",
    name: "Virgin Islands (U.S.)",
  },
  {
    code: "VN",
    name: "Viet Nam",
  },
  {
    code: "VU",
    name: "Vanuatu",
  },
  {
    code: "WF",
    name: "Wallis and Futuna",
  },
  {
    code: "WS",
    name: "Samoa",
  },
  {
    code: "YE",
    name: "Yemen",
  },
  {
    code: "YT",
    name: "Mayotte",
  },
  {
    code: "ZA",
    name: "South Africa",
  },
  {
    code: "ZM",
    name: "Zambia",
  },
  {
    code: "ZW",
    name: "Zimbabwe",
  },
];

export const getMarketOptions = (user) => {
  //user can have has_seller_account_eu, has_seller_account_na, has_seller_account_fe
  //filter availableDomains by usser has seller account on that server
  //return the filtered list
  const userMarkets = [];

  if (user?.has_seller_account_na === "true") {
    availableDomains
      .filter((domain) => domain.server === "na")
      .forEach((domain) => {
        userMarkets.push(domain);
      });
  }

  if (user?.has_seller_account_eu === "true") {
    availableDomains
      .filter((domain) => domain.server === "eu")
      .forEach((domain) => {
        userMarkets.push(domain);
      });
  }
  if (user?.has_seller_account_fe === "true") {
    availableDomains
      .filter((domain) => domain.server === "fe")
      .forEach((domain) => {
        userMarkets.push(domain);
      });
  }
  console.log("userMarkets", userMarkets);
  return userMarkets;
};
export function splitArrayIntoChunks(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}
export const requestHandler = async (
  method,
  url,
  body,
  isProtected,
  navigateFunc,
  navigateTo,
  tryCount = 0
) => {
  console.log(
    "request handler triggered",
    method,
    url,
    body,
    isProtected,
    navigateTo
  );
  const token = localStorage.getItem("idToken");
  if (isProtected && !token) {
    navigateFunc && navigateFunc(navigateTo);
    return;
  }

  let config = {};
  if (method === "delete" && body) {
    config = {
      data: body,
    };
  } else if (method === "get" && body) {
    config = {
      params: body, // Here the body is treated as query parameters for GET requests
    };
  } else if (body) {
    config = body;
  }

  const result = await axios[method](url, config)
    .then((res) => {
      console.log("successful response", res);
      return res;
    })
    .catch((err) => {
      console.log("error response", err);
      if (tryCount < 3) {
        const randomTime = Math.floor(Math.random() * 5) + 1;
        console.log("trying again in", randomTime, "seconds");
        setTimeout(() => {
          requestHandler(
            method,
            url,
            body,
            isProtected,
            navigateFunc,
            navigateTo,
            tryCount + 1
          );
        }, randomTime * 1000);
      } else {
        Auth.signOut();
        // setTimeout(() => {
        //   navigateFunc && navigateFunc(navigateTo);
        // }, 1000);
      }
    });

  return result;
};
export const getUserFromDb = async () => {
  return axios
    .get("/profile")
    .then((res) => {
      console.log("user fetched from db", res.data);
      //update user context

      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
export const makeReadable = (text) => {
  return text
    ?.split("_")
    ?.map((word) => {
      let words = word.split(/(?=[A-Z])/);
      return words
        ?.map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
        ?.join(" ");
    })
    ?.join(" ");
};
export const undoMakeReadable = (text) => {
  return text
    ?.split(" ")
    ?.map((word) => {
      return word.toLowerCase();
    })
    ?.join("_");
};
export const makeSmaller = (text, value) => {
  //if it is longer than value show until value index and  put ... at the end

  return text.length > value ? text.slice(0, value) + "..." : text;
};
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success({
    message: "Copied to clipboard",
  });
};
export const downloadAsinListExcel = async (arr, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Sheet 1");
  const preferences = {
    targetDomain: "www.amazon.com",
  };

  sheet.getRow(1).height = 50;
  sheet.getRow(1).border = {
    top: { style: "thick", color: { argb: "2c3e50" } },
    left: { style: "thin", color: { argb: "bdc3c7" } },
    bottom: { style: "thick", color: { argb: "2c3e50" } },
    right: { style: "thin", color: { argb: "bdc3c7" } },
  };

  sheet.columns = [
    {
      header: translate("Asin"),
      key: "asin",
      width: 20,
      height: 100,
    },
    {
      header: translate("Title"),
      key: "title",
      width: 35,
    },
    {
      header: translate("Url"),
      key: "url",
      width: 45,
    },

    {
      header: translate("Base Domain Price"),
      key: "base_domain_price",
      width: 10,
    },
    {
      header: translate("Target Domain Price"),
      key: "target_domain_price",
      width: 10,
    },
    {
      header: translate("Inbound Cost"),
      key: "shipping_cost",
      width: 10,
    },
    {
      header: translate("Fee"),
      key: "fee",
      width: 10,
    },
    {
      header: translate("Roi"),
      key: "roi",
      width: 10,
    },
    {
      header: translate("Profit Per Item"),
      key: "profit_per_item",
      width: 10,
    },
    {
      header: translate("Sales Rank"),
      key: "sales_rank",
      width: 10,
    },
    {
      header: translate("Sales Rank Category"),
      key: "sales_rank_category",
      width: 10,
    },
    {
      header: translate("Total Seller Count"),
      key: "total_seller_count",
      width: 10,
    },
    {
      header: translate("Fba Seller Count"),
      key: "fba_seller_count",
      width: 10,
    },
    {
      header: translate("Fbm Seller Count"),
      key: "fbm_seller_count",
      width: 10,
    },
    {
      header: translate("Is Amazon Seller"),
      key: "is_amazon_seller",
      width: 15,
    },
    {
      header: translate("Has Discounts"),
      key: "has_discounts",
      width: 5,
    },
    {
      header: translate("Hazmat"),
      key: "is_hazmat",
      width: 5,
    },
    {
      header: translate("Brand"),
      key: "brand",
      width: 15,
    },
    {
      header: translate("Binding"),
      key: "binding",
      width: 15,
    },

    {
      header: translate("Product Type Name"),
      key: "product_type_name",
      width: 20,
    },
    {
      header: translate("Item Weight (kg)"),
      key: "item_weight",
      width: 18,
    },
    {
      header: translate("Item Dimensions"),
      key: "item_dimensions",
      width: 30,
    },
    {
      header: translate("Package Dimensions"),
      key: "package_dimensions",
      width: 30,
    },
    {
      header: translate("Stock Error"),
      key: "stock_error",
      width: 5,
    },
    {
      header: translate("Sp Api Error"),
      key: "sp_api_error",
      width: 5,
    },
    {
      header: translate("Calculated"),
      key: "calculated",
      width: 5,
    },
    {
      header: translate("Created At"),
      key: "created_at",
      width: 20,
    },
    {
      header: translate("Updated At"),
      key: "updated_at",
      width: 20,
    },
  ];

  // Sütun başlıklarını bold yapma
  sheet.getRow(1).font = { bold: true };
  sheet.columns.forEach((column) => {
    column.eachCell((cell) => {
      cell.alignment = { vertical: "middle" };
    });
  });

  arr.sort((a, b) => {
    if (a.roi == null && b.roi != null) return 1;
    if (a.roi != null && b.roi == null) return -1;

    return b.roi - a.roi;
  });

  // Verileri ekleyin
  arr.forEach((item) => {
    const row = {
      asin: item.asin,
      title: item.title,
      url: `https://${preferences?.targetDomain}/dp/${item.asin}`,
      has_discounts: item.has_discounts,
      is_hazmat: item.is_hazmat,
      base_domain_price: item.base_domain_price,
      base_domain_converted_price: item.base_domain_converted_price,
      target_domain_price: item.target_domain_price,
      shipping_cost: item.shipping_cost,
      fee: item.fee,
      roi: item.roi,
      profit_per_item: item.profit_per_item,
      is_amazon_seller: item.is_amazon_seller,
      total_seller_count: item.total_seller_count,
      fba_seller_count: item.fba_seller_count,
      fbm_seller_count: item.fbm_seller_count,
      sales_rank: item.sales_rank,
      sales_rank_category: item.sales_rank_category,
      brand: item.brand,
      binding: item.binding,
      item_weight: parseFloat(item.item_weight).toFixed(3),
      product_type_name: item.product_type_name,
      item_dimensions: item.item_dimensions,
      package_dimensions: item.package_dimensions,
      stock_error: item.stock_error,
      sp_api_error: item.sp_api_error,
      calculated: item.calculated,
      created_at: new Date(item.created_at).toLocaleString(),
      updated_at: new Date(item.updated_at).toLocaleString(),
    };

    const newRow = sheet.addRow(row);

    // ROI color
    const roi = item.roi;
    newRow.getCell("roi").font = { color: { argb: "ffffff" }, bold: true };

    if (roi < 0)
      newRow.getCell("roi").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "c0392b" },
      };
    else if (roi > 0 && roi < 5)
      newRow.getCell("roi").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f0932b" },
      };
    else if (roi > 5 && roi < 15)
      newRow.getCell("roi").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "2ecc71" },
      };
    else if (roi > 15 && roi < 55)
      newRow.getCell("roi").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "6ab04c" },
      };
    else if (roi > 55 && roi < 100)
      newRow.getCell("roi").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "27ae60" },
      };
    else if (roi > 100)
      newRow.getCell("roi").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "16a085" },
      };
    else
      newRow.getCell("roi").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "bdc3c7" },
      };

    // Calculated styling
    const calculated = item.calculated;
    if (calculated === false)
      newRow.getCell("calculated").font = {
        color: { argb: "c0392b" },
        bold: true,
        size: 9,
      };
    else if (calculated === true)
      newRow.getCell("calculated").font = {
        color: { argb: "27ae60" },
        bold: true,
        size: 9,
      };
    else
      newRow.getCell("calculated").font = {
        color: { argb: "2c3e50" },
        bold: true,
        size: 9,
      };

    if (calculated === false) newRow.getCell("calculated").value = "False";
    else if (calculated === true) newRow.getCell("calculated").value = "True";
    else newRow.getCell("calculated").value = "Null";

    // stock_error color
    const stock_error = item.stock_error;
    if (stock_error === false)
      newRow.getCell("stock_error").font = {
        color: { argb: "c0392b" },
        bold: true,
        size: 9,
      };
    else if (stock_error === true)
      newRow.getCell("stock_error").font = {
        color: { argb: "27ae60" },
        bold: true,
        size: 9,
      };
    else
      newRow.getCell("stock_error").font = {
        color: { argb: "2c3e50" },
        bold: true,
        size: 9,
      };

    if (stock_error === false) newRow.getCell("stock_error").value = "False";
    else if (stock_error === true) newRow.getCell("stock_error").value = "True";
    else newRow.getCell("stock_error").value = "Null";

    // has_discounts color
    const has_discounts = item.has_discounts;
    if (has_discounts === false)
      newRow.getCell("has_discounts").font = {
        color: { argb: "c0392b" },
        bold: true,
        size: 9,
      };
    else if (has_discounts === true)
      newRow.getCell("has_discounts").font = {
        color: { argb: "27ae60" },
        bold: true,
        size: 9,
      };
    else
      newRow.getCell("has_discounts").font = {
        color: { argb: "2c3e50" },
        bold: true,
        size: 9,
      };

    if (has_discounts === false)
      newRow.getCell("has_discounts").value = "False";
    else if (has_discounts === true)
      newRow.getCell("has_discounts").value = "True";
    else newRow.getCell("has_discounts").value = "Null";

    // is_hazmat color
    const is_hazmat = item.is_hazmat;
    if (is_hazmat === false)
      newRow.getCell("is_hazmat").font = {
        color: { argb: "c0392b" },
        bold: true,
        size: 9,
      };
    else if (is_hazmat === true)
      newRow.getCell("is_hazmat").font = {
        color: { argb: "27ae60" },
        bold: true,
        size: 9,
      };
    else
      newRow.getCell("is_hazmat").font = {
        color: { argb: "2c3e50" },
        bold: true,
        size: 9,
      };

    if (is_hazmat === false) newRow.getCell("is_hazmat").value = "False";
    else if (is_hazmat === true) newRow.getCell("is_hazmat").value = "True";
    else newRow.getCell("is_hazmat").value = "Null";

    // sp_api_error color
    const sp_api_error = item.sp_api_error;
    if (sp_api_error === false)
      newRow.getCell("sp_api_error").font = {
        color: { argb: "c0392b" },
        bold: true,
        size: 9,
      };
    else if (sp_api_error === true)
      newRow.getCell("sp_api_error").font = {
        color: { argb: "27ae60" },
        bold: true,
        size: 9,
      };
    else
      newRow.getCell("sp_api_error").font = {
        color: { argb: "2c3e50" },
        bold: true,
        size: 9,
      };

    if (sp_api_error === false) newRow.getCell("sp_api_error").value = "False";
    else if (sp_api_error === true)
      newRow.getCell("sp_api_error").value = "True";
    else newRow.getCell("sp_api_error").value = "Null";

    // URL
    const url = item.url;
    newRow.getCell("url").value = {
      text: `https://${preferences?.targetDomain}/dp/${item.asin}`,
      hyperlink: `https://${preferences?.targetDomain}/dp/${item.asin}`,
      tooltip: `https://${preferences?.targetDomain}/dp/${item.asin}`,
    };
    newRow.getCell("url").font = {
      color: { argb: "3498db" },
      underline: true,
    };

    // title color
    const title = item.title;
    newRow.getCell("title").font = {
      color: { argb: "2c3e50" },
      bold: true,
      size: 9,
    };
  });

  // ASIN font
  sheet.getColumn("asin").eachCell((cell, rowNumber) => {
    if (rowNumber !== 1) {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF5F5F5" },
      };
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  const maxFileNameLength = 100;
  let trimmedFileName = fileName;

  if (fileName.length > maxFileNameLength) {
    trimmedFileName = fileName.substring(0, maxFileNameLength - 5);
  }

  a.download = trimmedFileName + ".xlsx";

  a.click();
  window.URL.revokeObjectURL(url);
};
export const waitSeconds = async (seconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
};
