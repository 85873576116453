import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import * as settingsActions from "../../hooks/actions";
import Language from "./_language";
import Menu from "./_menu";
import Notifications from "./_notification";
import OutsideAlerter from "./_useOutsideAlerter";
import User from "./_user";
import { NavLink } from "react-router-dom";
import translate from "../utils/translations";
import { requestHandler } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import FeatherIcon from "feather-icons-react";

const Header = ({ isPage, setSidebar, isLogged,
  setLogged, }) => {
  const navigate = useNavigate();
  const [isMenu, setMenu] = useState(false);
  const [isLanguage, setLanguage] = useState(false);
  const [isSelectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") || "EN"
  );
  const [notificationPopupVisible, setNotificationPopupVisible] =
    useState(false);
  const [isUser, setUser] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isAttention, setAttention] = useState(false);
  const [checkedAttention, setCheckedAttention] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      const browserLang = navigator.language.slice(0, 2).toUpperCase();
      const initialLang =
        browserLang === "TR" || browserLang === "DE" ? browserLang : "EN";
      localStorage.setItem("lang", initialLang);
      setSelectedLanguage(initialLang);
      document.documentElement.lang = initialLang.toLowerCase();
    }
  }, []);

  useEffect(() => {
    const currentLang = localStorage.getItem("lang"); // Mevcut dil, localStorage'dan alınır.
    const browserLanguage = navigator.language;
    console.log("browser language", browserLanguage);
    if (browserLanguage === "tr-TR") {
      localStorage.setItem("browserlang", "TR");
    } else if (browserLanguage === "en-US") {
      localStorage.setItem("browserlang", "EN");
    } else if (browserLanguage === "de-DE") {
      localStorage.setItem("browserlang", "DE");
    } else {
      localStorage.setItem("browserlang", "EN");
    }
    document.documentElement.lang = isSelectedLanguage.toLowerCase();
    if (currentLang !== isSelectedLanguage) {
      localStorage.setItem("lang", isSelectedLanguage);
      window.location.reload(); // Burada dil değişikliğini kontrol ediyoruz ve fark varsa sayfayı yeniliyoruz.
    }
  }, [isSelectedLanguage]);

  //list of client dashboard pages
  const clientDashboardPages = ["/client-dashboard", "/my-plan", "/tickets"];
  const adminDashboardPages = [
    "/admin-dashboard",
    "/balance",
    "/tiers",
    "/users",
    "/admin-asin-lists",
    "/admin-reports",
    "/admin-support",
    "/versions",
  ];
  const sharedLoggedInRequiredPages = [
    "/personal-settings",
    "/security-settings",
    "/marketplace-settings",
    "/notifications",
    "/integration-settings",
  ];

  const getCurrentPage = () => {
    const currentPage = window.location.pathname;

    if (clientDashboardPages.includes(currentPage)) {
      return "dashboard-client";
    } else if (adminDashboardPages.includes(currentPage)) {
      return "dashboard-admin";
    } else if (sharedLoggedInRequiredPages.includes(currentPage)) {
      return "shared-logged-in-required";
    } else {
      return "shared-logged-out-required";
    }
  };

  console.log("header isPage", isPage, getCurrentPage());

  // useEffect(() => {
  //   // localStorage.removeItem("checkedAttentionSaved");
  //   const isAttentionSaved = localStorage.getItem("checkedAttentionSaved") === "true";
  //   if (!isAttentionSaved) {
  //     setAttention(true);
  //   }
  // }, []);

  function closeAttention() {
    setAttention(false);
    if (checkedAttention) {
      localStorage.setItem("checkedAttentionSaved", "true");
    }
  }

  const getNotifications = async () => {
    const data = await requestHandler(
      "get",
      "/notifications",
      null,
      true,
      () => {
        if (
          [
            "dashboard-admin",
            "dashboard-client",
            "shared-logged-in-required",
          ].includes(getCurrentPage())
        ) {
          console.log("getNotifications navigate");
          return navigate;
        } else {
          return null;
        }
      },
      "/"
    ).then((res) => res?.data);
    console.log("notifications", data);
    setNotifications(data);
  };

  const getUser = async () => {
    const user = await requestHandler(
      "get",
      "/profile",
      null,
      true,
      () => {
        if (
          [
            "dashboard-admin",
            "dashboard-client",
            "shared-logged-in-required",
          ].includes(getCurrentPage())
        ) {
          return navigate;
        } else {
          return null;
        }
      },
      null
    ).then((res) => res?.data);
    console.log("user", user);
    if (user) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  };

  useEffect(() => {
    const isLogged = localStorage.getItem("idToken");
    if (isLogged) {
      console.log("header isLogged", isLogged);
      getUser();
      getNotifications();
    }
  }, []);

  const [isVisibleHeaderBanner, setIsVisibleHeaderBanner] = useState(() => {
    const isBannerClosed =
      localStorage.getItem("isHeaderBannerClosed12345") === "true";
    return !isBannerClosed;
  });

  const handleCloseHeaderBanner = () => {
    setIsVisibleHeaderBanner(false);
    localStorage.setItem("isHeaderBannerClosed12345", "true");
  };

  return (
    <>
      {isPage === "dashboard-admin" || isPage === "dashboard-client" ? (
        <></>
      ) : isVisibleHeaderBanner ? (
        <>
          <div className="rbt-header-campaign">
            <div className="inner_campaign">
              <div className="content">
                <span className="rbt-badge variation-02 bg-color-primary color-white radius-round">
                  {translate("Limited Time Offer")}
                </span>
                <span className="news-text color-white-off">
                  <img
                    src="/assets/img/hand-emojji.svg"
                    alt="Hand Emojji Images"
                  />{" "}
                  {translate(
                    "Don't miss out on our exclusive deal! Buy our $50 or $100 packages and get extra tokens for free!"
                  )}
                </span>
              </div>
              <div className="right-button">
                <NavLink to="/pricing" className="rbt-btn-link color-white" onClick={handleCloseHeaderBanner}>
                  <span>
                    {translate("Choose Package")}{" "}
                    <i>
                      <FeatherIcon icon={"arrow-right"} />
                    </i>
                  </span>
                </NavLink>
              </div>
              <div className="icon-close position-right">
                <button
                  onClick={handleCloseHeaderBanner}
                  className="rbt-round-btn btn-white-off bgsection-activation"
                >
                  <FeatherIcon icon={"x"} />
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <header
        className={`header js-header authorization ${
          !isVisibleHeaderBanner ||
          isPage === "dashboard-admin" ||
          isPage === "dashboard-client"
            ? " no__banner "
            : " "
        } ${
          isPage === "dashboard-admin" || isPage === "dashboard-client"
            ? " full"
            : " "
        }`}
      >
        <div className="header__center center">
          <NavLink
            className="header__logo"
            to="/"
            onClick={() => {
              setMobile(false);
              setSidebar(false);
            }}
          >
            <img
              className="some-icon"
              src="/assets/img/logo-dark4.png"
              alt=""
            />
            <img
              className="some-icon-dark"
              src="/assets/img/logo-dark4.png"
              alt=""
            />
            <span>
              Kepicker <div className="beta_tag">Beta</div>
            </span>
          </NavLink>
          <div
            className={`header__wrapper js-header-wrapper ${
              isMobile && " visible"
            }`}
          >
            <OutsideAlerter setMenu={setMenu}>
              <Menu isMenu={isMenu} setMenu={setMenu} setMobile={setMobile} />
            </OutsideAlerter>

            {isLogged && (
              <NavLink
                className="header__item"
                to={
                  isPage !== "dashboard-admin"
                    ? "/client-dashboard"
                    : "/admin-dashboard"
                }
                onClick={() => setMobile(false)}
              >
                {translate("Dashboard")}
              </NavLink>
            )}
          </div>

          <OutsideAlerter setLanguage={setLanguage}>
            <Language
              isLanguage={isLanguage}
              setLanguage={setLanguage}
              isSelectedLanguage={isSelectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          </OutsideAlerter>

          {isLogged && (
            <a
              className="button button-stroke button-small header__button iconic_nav ml_h"
              target="_blank"
              href="https://chromewebstore.google.com/detail/kepicker/hnpablpopdlabbiopkihkmlfmkgpihdc?hl=en"
              // onClick={() => toast.error(translate("The extension is not yet live!"))}
            >
              <span>
                <img src="/assets/img/chrome.svg"></img>
              </span>
              {translate("Chrome Store")}
            </a>
          )}

          {!isLogged ? (
            <>
              {/* <NavLink
                className="button-small header__button ml_c"
                to="/signup"
              >
                Sign Up
              </NavLink> */}
              <NavLink
                className="button button-stroke button-small header__button ml_c"
                to="/login"
              >
                {translate("Sign In")}
              </NavLink>
              <a
                className="button button-stroke button-small header__button iconic_nav ml_h"
                target="_blank"
                href="https://chromewebstore.google.com/detail/kepicker/hnpablpopdlabbiopkihkmlfmkgpihdc?hl=en"
              >
                <span>
                  <img src="/assets/img/chrome.svg"></img>
                </span>
                {translate("Chrome Store")}
              </a>
            </>
          ) : (
            <>
              <OutsideAlerter
                setNotificationPopupVisible={setNotificationPopupVisible}
              >
                <Notifications
                  notificationPopupVisible={notificationPopupVisible}
                  setNotificationPopupVisible={setNotificationPopupVisible}
                  notifications={notifications}
                  setNotifications={setNotifications}
                />
              </OutsideAlerter>

              <OutsideAlerter setUser={setUser}>
                <User
                  isUser={isUser}
                  setUser={setUser}
                  setLogged={setLogged}
                  isPage={isPage}
                />
              </OutsideAlerter>
            </>
          )}

          <button
            onClick={() => {
              setMobile(!isMobile);
              setSidebar(false);
            }}
            className={`header__burger js-header-burger ${
              isMobile && " active"
            }`}
          ></button>
        </div>
      </header>

      {isAttention && (
        <div className="pricing_area attention_pls add_price_pavkages">
          <div className="pricing_box">
            <div className="pricing_wrap">
              <div className="p_badge">
                <img src="/assets/img/attention.svg" alt="Attention Badge" />
              </div>
              <div className="p_sub_title">
                {translate("Important Notice!")}
              </div>
              <div className="p_text">
                {translate(
                  "Our system is currently in Beta, and we have kept our pricing policy as affordable as possible. If you come across an issue while using the system, please let us know. Our support team will provide extra usage limits to compensate for any losses. Thank you for your understanding."
                )}
              </div>
              <div className="undrstnd_chk">
                <div className="radio-pill">
                  <input
                    type="checkbox"
                    name="attention"
                    id="attention"
                    checked={checkedAttention}
                    onChange={() => setCheckedAttention(!checkedAttention)}
                  />
                  <label className="radio-pill-inner cursorp" for="attention">
                    <span>
                      {translate("Please do not show this screen again.")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="p_button">
                <button
                  onClick={closeAttention}
                  className="button view__button"
                >
                  {translate("OK thanks.")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLogged: state.settings.isLogged,
});
const mapDispatchToProps = (dispatch) => ({
  setLogged: (e) => dispatch(settingsActions.setLogged(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
