import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import FilterDrawer from "../../shared/filter/filter_drawer";
import FeatherIcon from "feather-icons-react";
import translate from "../../shared/utils/translations";
import {
  SVGAsin360,
  SVGHistory,
  SVGKeywordTool,
  SVGTickets,
} from "../../common/other_elements/svg_icons";
import { requestHandler } from "../../shared/utils/helpers";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip } from "antd";

const SidebarClientDashboard = ({
  isSidebar,
  setSidebar,
  setBuyTokenDrawer,
}) => {
  const [isSettings, setSettings] = useState(false);
  const [isSettingsCheck, setSettingsCheck] = useState(false);
  const [isDrawer, setDrawer] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();

  // useEffect(() => {
  //   if (
  //     location.pathname === "/personal-settings" ||
  //     location.pathname === "/security-settings" ||
  //     location.pathname === "/notification-settings" ||
  //     location.pathname === "/markets" ||
  //     isSettingsCheck
  //   ) {
  //     setSettings(true);
  //   } else {
  //     setSettings(false);
  //     setSettingsCheck(false);
  //   }
  // }, [isSettings, isSettingsCheck]);

  const [user, setUser] = useState({});
  const getUser = async () => {
    requestHandler("get", `/profile`, null, true, navigate, "/").then((res) => {
      console.log(res);
      setUser(res?.data);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <FilterDrawer isDrawer={isDrawer} setDrawer={setDrawer} />

      {isSidebar && (
        <div onClick={() => setSidebar(false)} className="sidebar_bgg"></div>
      )}

      <div className={`onex_wl__sidebar ${isSidebar && " actived"}`}>
        <div className="onex_wl__group">
          <div className="onex_wl__top">
            <div className="onex_wl__color bg__1"></div>
            {translate("Dashboard")}
          </div>
          <div className="onex_wl__menu">
            <NavLink
              className="onex_wl__link"
              to="/client-dashboard"
              onClick={() => setSidebar(false)}
            >
              <FeatherIcon icon="monitor" className={"icon f_icn"} />
              {translate("Dashboard")}
            </NavLink>

            <NavLink
              className="onex_wl__link has__beta"
              to="/asin360"
              onClick={() => setSidebar(false)}
            >
              <SVGAsin360 />
              {translate("Asin 360°")}
              {/* <div className="beta_tag gg">Beta</div> */}
            </NavLink>

            <NavLink
              className="onex_wl__link"
              to="/favorites"
              onClick={() => setSidebar(false)}
            >
              <FeatherIcon icon="star" className={"icon f_icn"} />
              {translate("Favorites")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/history"
              onClick={() => setSidebar(false)}
            >
              <SVGHistory />
              {translate("History")}
            </NavLink>
            {/* <NavLink
              className="onex_wl__link has__beta"
              to="/keyword-tool"
              onClick={() => setSidebar(false)}
            >
              <SVGKeywordTool />
              {translate("Keyword Tool")}
              <div className="beta_tag gg">Beta</div>
            </NavLink> */}

            <NavLink
              className="onex_wl__link"
              to="/my-plan"
              onClick={() => setSidebar(false)}
            >
              <svg className="icon icon-wallet">
                <use xlinkHref="#icon-wallet"></use>
              </svg>
              {translate("Plan & Billing")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/tickets"
              onClick={() => setSidebar(false)}
            >
              <SVGTickets />
              {translate("Tickets")}
            </NavLink>
            {/* <NavLink
              className="onex_wl__link"
              to="/affilliate"
              onClick={() => setSidebar(false)}
            >
              <svg className="icon icon-coin">
                <use xlinkHref="#icon-coin"></use>
              </svg>
              {translate("Affilliate")}
            </NavLink> */}
            <div
              className={`sidebar__item sidebar__item_dropdown wide ${
                isSettings && " active"
              }`}
            >
              <button
                className={`onex_wl__link js-popup-open openable_link ${
                  isSettings
                    ? " active"
                    : location.pathname === "/personal-settings"
                    ? " active"
                    : location.pathname === "/security-settings"
                    ? " active"
                    : location.pathname === "/notification-settings"
                    ? " active"
                    : location.pathname === "/integration-settings"
                    ? " active"
                    : location.pathname === "/markets"
                    ? " active"
                    : " "
                }`}
                onClick={() => {
                  setSettings(!isSettings);
                  setSettingsCheck(!isSettingsCheck);
                }}
              >
                <svg className="icon icon-cog">
                  <use xlinkHref="#icon-cog"></use>
                </svg>
                {translate("Settings")}
                <svg className="icon icon-arrow-down arrw">
                  <use xlinkHref="#icon-arrow-down"></use>
                </svg>
              </button>
              <div className="sidebar__body d_none">
                <NavLink
                  className="sidebar__link"
                  to="/personal-settings"
                  onClick={() => setSidebar(false)}
                >
                  <div className="onex_wl__color bg__4 und_lnk"></div>
                  {translate("Personal info")}
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                </NavLink>
                <NavLink
                  className="sidebar__link"
                  to="/security-settings"
                  onClick={() => setSidebar(false)}
                >
                  <div className="onex_wl__color bg__5 und_lnk"></div>
                  {translate("Security")}
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                </NavLink>
                {/* <NavLink
                  className="sidebar__link"
                  to="/notification-settings"
                  onClick={() => setSidebar(false)}
                >
                  Notification
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                </NavLink>{" "} */}
                <NavLink
                  className="sidebar__link"
                  to="/marketplace-settings"
                  onClick={() => setSidebar(false)}
                >
                  <div className="onex_wl__color bg__6 und_lnk"></div>
                  {translate("Marketplace settings")}
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                </NavLink>
                <NavLink
                  className="sidebar__link"
                  to="/integration-settings"
                  onClick={() => setSidebar(false)}
                >
                  <div className="onex_wl__color bg__7 und_lnk"></div>
                  {translate("3rd-party Integrations")}
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                </NavLink>
              </div>
            </div>
            {/* <NavLink
              className="onex_wl__link"
              to="/referrals"
              onClick={() => setSidebar(false)}
            >
              <svg className="icon icon-share">
                <use xlinkHref="#icon-share"></use>
              </svg>
              Referrals
            </NavLink> */}
          </div>
        </div>
        <div className="onex_wl__btns">
          {/* <button
            className="button button-small onex_wl__button"
            onClick={() => {
              setDrawer(!isDrawer);
              setSidebar(false);
            }}
          >
            Filters
          </button> */}

          <div className="card__kpp_body gg cc sdbar">
            {user?.tier_id && user.tier_id > 1 ? (
              <Tooltip title={translate("More tokens")} placement="top">
                <a
                  href="#!"
                  role="button"
                  className="up_btn token_bt hh"
                  onClick={() => setBuyTokenDrawer(true)}
                >
                  <FeatherIcon icon={"plus"} />
                </a>
              </Tooltip>
            ) : (
              <Tooltip title={translate("More tokens")} placement="top">
                <a
                  href="#!"
                  role="button"
                  className="up_btn token_bt hh"
                  onClick={() =>
                    toast.error(
                      translate(
                        "If you want to buy extra comparison tokens, you need to upgrade your package!"
                      )
                    )
                  }
                >
                  <FeatherIcon icon={"plus"} />
                </a>
              </Tooltip>
            )}
            <div className="plan__ihm">
              <h5>{translate("Comparison Tokens")}</h5>
              <div className="inf__ihm nnb">
                {user?.token_count}
                <small className="tkn__type">
                  {user?.tier_id && user.tier_id <= 1
                    ? translate("for Once")
                    : translate("Monthly")}
                </small>
              </div>

              <div className="progress-bar--usage">
                <span className="bar--usage">
                  <span
                    style={{
                      width: `${
                        user?.token_count >= 1000
                          ? 100
                          : (user?.token_count / 1000) * 100
                      }%`,
                      background:
                        user?.token_count >= 1000
                          ? "#00b576"
                          : user?.token_count >= 800
                          ? "#7dc443"
                          : user?.token_count >= 550
                          ? "#cdd53d"
                          : user?.token_count >= 250
                          ? "#e59a39"
                          : "#f44336",
                    }}
                    className="progress--usage"
                  ></span>
                </span>
              </div>
            </div>
            <div className="plan__ihm">
              <h5>{translate("Product Based Info Limit")}</h5>
              <div className="inf__ihm nnb">
                {user?.asin_count}
                <small className="tkn__type">
                  {user?.tier_id && user.tier_id <= 1
                    ? translate("for Once")
                    : translate("Daily")}
                </small>
              </div>

              <div className="progress-bar--usage">
                <span className="bar--usage">
                  <span
                    style={{
                      width: `${
                        user?.asin_count >= 400
                          ? 100
                          : (user?.asin_count / 400) * 100
                      }%`,
                      background:
                        user?.asin_count >= 400
                          ? "#00b576"
                          : user?.asin_count >= 300
                          ? "#7dc443"
                          : user?.asin_count >= 250
                          ? "#cdd53d"
                          : user?.asin_count >= 200
                          ? "#e59a39"
                          : "#f44336",
                    }}
                    className="progress--usage"
                  ></span>
                </span>
              </div>
            </div>
          </div>

          {user?.user_tier !== "pro" && (
            <NavLink
              to="/pricing"
              className="button-stroke button-small onex_wl__button button_upg"
              onClick={() => setSidebar(false)}
              style={{ marginTop: "15px" }}
            >
              {translate("Upgrade plan")}
            </NavLink>
          )}
        </div>
      </div>
    </>
  );
};

export default SidebarClientDashboard;
