import React, { useCallback, useEffect } from "react";
import { Dropdown, Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip, Modal, Button, Input } from "antd";
import { Slide } from "react-slideshow-image";
import FeatherIcon from "feather-icons-react";
import "react-slideshow-image/dist/styles.css";
import translate from "../../../../shared/utils/translations";
import OutsideAlerter from "../../../../shared/header/_useOutsideAlerter";
import MarketComparison from "./_market_comparsion";
import KepickerCalculator from "./_kepicker_calculator";
import DimensionsInfos from "./_dimensions_infos";
import RoiFees from "./_roi_fees";
import MainInfos from "./_main_infos";
import StockFetcher from "./_stock_fetcher";
import AdditionalInfos from "./_additional_infos";
import KeepaData from "./_keepa_data";
import AsinFetcher from "./_asin_fetcher";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import {
  copyToClipboard,
  requestHandler,
  makeReadable,
  waitSeconds,
} from "../../../../shared/utils/helpers";
import NoImage from "../../../../../assets/img/no_image.png";
import LoaderPage from "../../../../common/loaders/loader_page";
import LoaderPBI from "../../../../common/loaders/loader_pbi";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import ProductMetrics from "./_product_metrics";

const PBIPage = ({ setPage, setBuyTokenDrawer }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });
  const { asin } = useParams();

  const [isMore, setMore] = useState(false);
  const [isTab, setTab] = useState("mc");
  const [isAsinFetcher, setAsinFetcher] = useState(false);
  const [keepaData, setKeepaData] = useState({});
  const [additionalData, setAdditionalData] = useState({});
  const [baseOffersData, setBaseOffersData] = useState({});
  const [targetOffersData, setTargetOffersData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [mainData, setMainData] = useState({});
  const [basePrice, setBasePrice] = useState(0);
  const [basePriceNotConverted, setBasePriceNotConverted] = useState(0);
  const [targetPrice, setTargetPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [preferenceData, setPreferenceData] = useState({});
  const [preferences, setPreferences] = useState([]);
  const [isFav, setFav] = useState(false);
  const [note, setNote] = useState("");
  const [isNoteModal, setNoteModal] = useState(false);
  const [isNoteBtn, setNoteBtn] = useState(false);
  const { TextArea } = Input;

  const getPostfixFromUrl = (url) => {
    //"https://www.amazon.co.uk/dp/B01HB2DCT0";
    //extract .co.uk
    let linkPostFix = url?.replace("https://www.amazon", "");
    //remove /dp...
    linkPostFix = linkPostFix?.substring(0, linkPostFix.indexOf("/dp"));
    return linkPostFix;
  };
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(isNaN(value) ? 0 : value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const getData = async () => {
    const res = await requestHandler(
      "post",
      `/sp-api/roi`,
      { asin },
      true,
      null,
      null
    );
    setKeepaData(res?.data?.keepaData);
    setAdditionalData(res?.data?.additionalData);
    setBaseOffersData(res?.data?.baseOffers);
    setTargetOffersData(res?.data?.targetOffers);
    setMainData(res?.data);
    setBasePrice(parseFloat(res?.data?.baseDomainPrice));
    setTargetPrice(parseFloat(res?.data?.targetOffers?.price));
    setShippingCost(parseFloat(res?.data?.shippingCost));
    setImageUrl(res?.data?.image_url);
    setPreferenceData(res?.data?.preference);
    setBasePriceNotConverted(parseFloat(res?.data?.baseDomainPriceMain));
    setNoteBtn(res?.data?.note ? true : false);
    setFav(res?.data?.isFavorite);
  };

  const getPreferences = async () => {
    const res = await requestHandler(
      "get",
      `/preferences`,
      null,
      true,
      null,
      null
    );
    setPreferences(res?.data?.preferences);
  };

  const manualCalculate = async () => {
    const res = await requestHandler(
      "post",
      `/sp-api/roiManual`,
      {
        asin,
        basePrice,
        targetPrice,
        shippingCost,
        monthlyStorageFee: mainData?.monthlyStorageFee,
        weight: mainData?.weight,
      },
      true,
      null,
      null
    );
    setMainData(res?.data);
    setBasePrice(res?.data?.baseDomainPrice);
    setTargetPrice(res?.data?.targetDomainPrice);
    setShippingCost(res?.data?.shippingCost);
  };
  const manualCalculateOnBaseCurrency = async () => {
    const res = await requestHandler(
      "post",
      `/sp-api/roiManual`,
      {
        asin,
        basePrice,
        basePriceNotConverted,
        targetPrice,
        shippingCost,
        monthlyStorageFee: mainData?.monthlyStorageFee,
        weight: mainData?.weight,
      },
      true,
      null,
      null
    );
    setMainData(res?.data);
    setBasePrice(res?.data?.baseDomainPrice);
    setTargetPrice(res?.data?.targetDomainPrice);
    setShippingCost(res?.data?.shippingCost);
  };
  useEffect(() => {
    getData();
    getPreferences();
  }, []);
  const debouncedBasePrice = useDebounce(basePrice, 500); // 500 ms delay
  const debouncedTargetPrice = useDebounce(targetPrice, 500);
  const debouncedShippingCost = useDebounce(shippingCost, 500);
  const debouncedBasePriceNotConverted = useDebounce(
    basePriceNotConverted,
    500
  );
  useEffect(() => {
    if (
      debouncedBasePrice &&
      debouncedTargetPrice &&
      debouncedShippingCost &&
      (debouncedBasePrice !== parseFloat(mainData?.baseDomainPrice) ||
        debouncedTargetPrice !== parseFloat(targetOffersData?.price) ||
        debouncedShippingCost !== parseFloat(mainData?.shippingCost))
    ) {
      console.log("manualCalculate called");

      manualCalculate();
    }
  }, [debouncedBasePrice, debouncedTargetPrice, debouncedShippingCost]);

  useEffect(() => {
    console.log(
      "debouncedBasePriceNotConverted",
      debouncedBasePriceNotConverted
    );
    console.log("baseOffersData?.price", baseOffersData?.price);
    if (
      debouncedBasePriceNotConverted &&
      debouncedTargetPrice &&
      debouncedShippingCost &&
      baseOffersData?.price &&
      debouncedBasePriceNotConverted > 0 &&
      parseFloat(debouncedBasePriceNotConverted) !==
        parseFloat(baseOffersData?.price)
    ) {
      console.log("manualCalculateOnBaseCurrency called");
      // manualCalculateOnBaseCurrency();
    }
  }, [debouncedBasePriceNotConverted]);

  const selectPreference = async (id) => {
    setBasePrice();
    setTargetPrice();
    setShippingCost();

    setMainData({});
    const res = await requestHandler(
      "put",
      `/preferences/select/${id}`,
      { asin, preference_id: id },
      true,
      null,
      null
    ).then((res) => {
      getData();
    });
  };
  const toggleFavorites = async () => {
    await requestHandler(
      "put",
      `/asin/favorite/${asin}`,
      {
        asin,
        title: additionalData?.title,
        image: additionalData?.image,
      },
      true,
      null,
      null
    ).then(() => {
      if (isFav) {
        setFav(false);
        toast.error(translate("The product has been removed from favorites!"));
      } else {
        setFav(true);
        toast.success(translate("The product has been added to favorites!"));
      }
    });
  };

  const saveNote = async (note) => {
    const res = await requestHandler(
      "put",
      `/asin/note/${asin}`,
      { note, asin },
      true,
      null,
      null
    );
    if (res?.error) {
      toast.error(res?.error);
    } else {
      toast.success(
        translate(
          note ? "Note applied successfully!" : "Note deleted successfully!"
        )
      );
      setNoteModal(false);
      setNoteBtn(true);
    }
  };

  const handleApplyNote = () => {
    if (note.trim() === "") {
      toast.error(translate("Note cannot be empty!"));
    } else {
      saveNote(note);
    }
  };

  const handleRemoveNote = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setNote("");
        saveNote();
      }
    });
  };

  const [isSheet, setSheet] = useState(false);
  const [isSheetsDrop, setSheetsDrop] = useState(false);
  const addToSheet = () => {
    requestHandler("post", "/sheet", { mainData }, true, null, null).then(
      (res) => {
        toast.success(translate("Asin added to Google Sheet successfully!"));
        setSheet(true);
        console.log(res);
      }
    );
  };

  let navigate = useNavigate();
  const [user, setUser] = useState({});
  const getUser = async () => {
    requestHandler("get", `/profile`, null, true, navigate, "/").then((res) => {
      console.log(res);
      setUser(res?.data);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Helmet>
        <title>{translate("Product Based Info")}</title>
      </Helmet>

      <Modal
        title={translate("Product Note")}
        centered
        visible={isNoteModal}
        onOk={() => setNoteModal(false)}
        onCancel={() => setNoteModal(false)}
        footer={[
          <Button key="back" onClick={handleRemoveNote}>
            {translate("Remove Note!")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleApplyNote}>
            {translate("Apply")}
          </Button>,
        ]}
      >
        <div className="coppyy_Asin_Area">
          <p className="ant-copiedd-text">
            {translate("Write your note here:")}
          </p>
          <TextArea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder={translate("Add a note..")}
            autoSize={{
              minRows: 5,
              maxRows: 6,
            }}
          />
        </div>
      </Modal>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper ppbbi">
            <div className="onex_wl__list pbbi__body ps_rrel">
              {user?.asin_count >= 1 ? (
                <>
                  <div className="cmp_drw_title tt">
                    <h4>{translate("Product Based Info")}</h4>{" "}
                    <div className="rght_itms tt">
                      <div className="field pbi__selectt">
                        <label className="field__label">
                          ={translate("Current Setting")}
                        </label>
                        <Tooltip
                          title={translate("Current Setting")}
                          placement="bottom"
                        >
                          <div className="select__field">
                            <select
                              className="select nice-select app d_block"
                              onChange={(e) => {
                                selectPreference(e.target.value);
                              }}
                            >
                              {preferences?.map((i) => {
                                return (
                                  <option selected={i.selected} value={i.id}>
                                    {i.is_fba ? "FBA" : "FBM"} {i?.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </Tooltip>
                      </div>
                      {keepaData?.variationCount > 0 ||
                      additionalData?.is_meltable ||
                      additionalData?.is_hazmat ? (
                        <div className="prdct__Tags">
                          {keepaData?.variationCount > 0 && (
                            <Tooltip
                              title={translate(
                                "Variation Product - Variation Count"
                              )}
                              placement="bottom"
                            >
                              <div className="pb_icn_info">
                                <img
                                  src={"/assets/img/icons8-variation-50.png"}
                                />
                                <span className="vari__bulp">
                                  {keepaData?.variationCount}
                                </span>
                              </div>
                            </Tooltip>
                          )}

                          {additionalData?.is_meltable && (
                            <Tooltip
                              title={translate("Heat Sensitive - Meltable")}
                              placement="bottom"
                            >
                              <div className="pb_icn_info">
                                <img
                                  className="tt"
                                  src="/assets/img/icons8-heat-48.png"
                                />
                              </div>
                            </Tooltip>
                          )}
                          {additionalData?.is_hazmat && (
                            <Tooltip
                              title={translate("Hazmat")}
                              placement="bottom"
                            >
                              <div className="pb_icn_info">
                                <img src="/assets/img/icons8-skull-64.png" />

                                <div className="hover_card_details">
                                  <div>
                                    <ul>
                                      {additionalData?.hazmat_data
                                        ?.filter(
                                          (i) => i["aspect"] !== "exception"
                                        )
                                        .map((i, index) => {
                                          delete i["marketplace_id"];
                                          return (
                                            <li key={index}>
                                              {makeReadable(i["aspect"])}:{" "}
                                              {i["value"]}
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}

                      <Tooltip
                        title={translate("Product Note")}
                        placement="left"
                      >
                        <button
                          onClick={() => setNoteModal(true)}
                          className={`button-stroke button-small activity__button js-activity-button cc icn dyno_bt ${
                            isNoteBtn && " active"
                          }`}
                        >
                          <span>
                            <FeatherIcon icon={"edit"} />
                          </span>
                        </button>
                      </Tooltip>

                      <div className="google_sheet_area">
                        <Tooltip
                          title={translate("Add to Google Sheet")}
                          placement="left"
                        >
                          <button
                            onClick={addToSheet}
                            className={`button-stroke button-small dff__btn sheet_btn active ${
                              isSheet && " active"
                            }`}
                          >
                            <span>
                              <img src="/assets/img/google-sheets.svg" />
                            </span>
                          </button>
                        </Tooltip>

                        {/* {isSheet && (
                          <OutsideAlerter setSheetsDrop={setSheetsDrop}>
                            <div
                              className={`select-menu__kkp ${
                                isSheetsDrop && " active"
                              }`}
                            >
                              <Tooltip
                                title={translate(
                                  "Select which Google Sheet it should be sent to."
                                )}
                                placement="left"
                              >
                                <button
                                  onClick={() => setSheetsDrop(!isSheetsDrop)}
                                  className={`button-stroke button-small dff__btn`}
                                >
                                  <span>
                                    <FeatherIcon icon={"tool"} />
                                  </span>
                                </button>
                              </Tooltip>

                              <div className="options__kkp tt">
                                <div className="radios_groups">
                                  <div className="kp_radio-itm">
                                    <input
                                      className="kpn_radio-input"
                                      name="google--sheets"
                                      id="sheet1"
                                      type="radio"
                                    />
                                    <label
                                      className="kpn_radio-label"
                                      for="sheet1"
                                    >
                                      <span className="kpn_radio-inner-circle"></span>
                                      <span className="radd__title">
                                        Kepicker Sheet ##1
                                      </span>
                                    </label>
                                  </div>
                                  <div className="kp_radio-itm">
                                    <input
                                      className="kpn_radio-input"
                                      name="google--sheets"
                                      id="sheet2"
                                      type="radio"
                                    />
                                    <label
                                      className="kpn_radio-label"
                                      for="sheet2"
                                    >
                                      <span className="kpn_radio-inner-circle"></span>
                                      <span className="radd__title">
                                        Kepicker Sheet ##2
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="dif__lii">
                                  <div className="dropp__footer">
                                    <div className="rr__hdr">
                                      <NavLink
                                        className={"pbi__link table_icon"}
                                        to="/integration-settings"
                                      >
                                        {translate("Add New Google Sheet")}
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </OutsideAlerter>
                        )} */}
                      </div>

                      <Tooltip
                        title={translate("Add to Favorites")}
                        placement="left"
                      >
                        <button
                          onClick={() => toggleFavorites()}
                          className={`button-stroke button-small activity__button js-activity-button cc icn dyno_bt ${
                            isFav && " active"
                          }`}
                        >
                          <span>
                            <FeatherIcon icon={"star"} />
                          </span>
                        </button>
                      </Tooltip>

                      <Tooltip
                        title={translate("Re-calculate")}
                        placement="left"
                      >
                        <button
                          onClick={() => {
                            setMainData({});
                            getData();
                          }}
                          className="button-stroke button-small activity__button js-activity-button cc icn"
                        >
                          <span>
                            <FeatherIcon icon={"refresh-ccw"} />
                          </span>
                        </button>
                      </Tooltip>

                      <OutsideAlerter setMore={setMore}>
                        <div
                          className={`select-menu__kkp ${isMore && " active"}`}
                        >
                          <Tooltip title={translate("More")} placement="left">
                            <button
                              onClick={() => setMore(!isMore)}
                              className="button-stroke button-small activity__button js-activity-button cc icn"
                            >
                              <span>
                                <FeatherIcon icon={"more-horizontal"} />
                              </span>
                            </button>
                          </Tooltip>

                          <ul className="options__kkp">
                            <li className="option__kkp">
                              <a
                                href={`https://www.google.com/search?q=${additionalData?.title
                                  ?.trim()
                                  .replace(/\s/g, "+")}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="/assets/img/icons8-google-96.png"></img>
                                <span className="option-text">
                                  {translate("Search on Google")}
                                </span>
                              </a>
                            </li>
                            <li className="option__kkp">
                              <a
                                href={`https://sellercentral.amazon${getPostfixFromUrl(
                                  mainData?.product_link
                                )}/hz/approvalrequest/restrictions/approve?asin=${asin}`}
                                target="_blank"
                              >
                                <img src="/assets/img/icons8-amazon-96.png"></img>
                                <span className="option-text">
                                  {translate("Check on Amazon Seller Central")}
                                </span>
                              </a>
                            </li>

                            <div className="dividerr_tt"></div>

                            <li
                              className="option__kkp"
                              onClick={() => copyToClipboard(asin)}
                            >
                              <FeatherIcon icon={"copy"} />
                              <small>ASIN</small>
                              <span className="option-text">{asin}</span>
                            </li>
                            <li
                              className="option__kkp"
                              onClick={() =>
                                copyToClipboard(keepaData?.ean?.join(","))
                              }
                            >
                              <FeatherIcon icon={"copy"} />
                              <small>EAN</small>
                              <span className="option-text">
                                {keepaData?.ean?.join(",")}
                              </span>
                            </li>
                            <li
                              className="option__kkp"
                              onClick={() =>
                                copyToClipboard(keepaData?.upc?.join(","))
                              }
                            >
                              <FeatherIcon icon={"copy"} />
                              <small>UPC</small>
                              <span className="option-text">
                                {keepaData?.upc?.join(",")}
                              </span>

                              <div className="srchh_div">
                                <Tooltip
                                  title={"Barcode Lookup"}
                                  placement="top"
                                >
                                  <a
                                    href={`https://www.barcodelookup.com/${keepaData?.upc?.[0]}`}
                                    target="_blank"
                                  >
                                    <FeatherIcon icon={"link-2"} />
                                  </a>
                                </Tooltip>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </OutsideAlerter>
                    </div>
                  </div>

                  <div className="comparsion__body">
                    {!mainData?.asin ? <LoaderPBI /> : <></>}

                    <div className="left__wrpp">
                      <MainInfos
                        mainData={mainData}
                        keepaData={keepaData}
                        additionalData={additionalData}
                        targetOffersData={targetOffersData}
                        imageUrl={imageUrl}
                        noImg={NoImage}
                      />
                      <ProductMetrics
                        mainData={mainData}
                        keepaData={keepaData}
                        additionalData={additionalData}
                        targetOffersData={targetOffersData}
                      />

                      <RoiFees
                        mainData={mainData}
                        targetOffersData={targetOffersData}
                      />

                      <KepickerCalculator
                        mainData={mainData}
                        setBasePrice={setBasePrice}
                        setTargetPrice={setTargetPrice}
                        setShippingCost={setShippingCost}
                        basePrice={basePrice}
                        targetPrice={targetPrice}
                        shippingCost={shippingCost}
                        reset={getData}
                      />

                      <DimensionsInfos additionalData={additionalData} />
                    </div>
                    <div className="dvd_pb"></div>
                    <div className="right__wrpp">
                      <div className="nav sheets_nav">
                        <button
                          className={`nav__link cursorp ${
                            isTab === "mc" ? " active" : " "
                          }`}
                          onClick={() => setTab("mc")}
                        >
                          {translate("Market Comparison")}
                        </button>
                        {/* <button
                          className={`nav__link cursorp ${
                            isTab === "sf" ? " active" : " "
                          }`}
                          onClick={() => setTab("sf")}
                        >
                          {translate("Stock Fetcher")}
                        </button> */}
                        <button
                          className={`nav__link cursorp ${
                            isTab === "ai" ? " active" : " "
                          }`}
                          onClick={() => setTab("ai")}
                        >
                          {translate("Additional Infos")}
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isTab === "kd" ? " active" : " "
                          }`}
                          onClick={() => setTab("kd")}
                        >
                          {translate("Keepa Data")}
                        </button>
                      </div>

                      {isTab === "mc" ? (
                        <MarketComparison
                          setAsinFetcher={setAsinFetcher}
                          baseOffersData={baseOffersData}
                          targetOffersData={targetOffersData}
                          setBasePriceNotConverted={setBasePriceNotConverted}
                          basePriceNotConverted={basePriceNotConverted}
                          preferenceData={preferenceData}
                          asin={asin}
                          mainData={mainData}
                        />
                      ) : (
                        <></>
                      )}
                      {isTab === "sf" ? (
                        <StockFetcher
                          setAsinFetcher={setAsinFetcher}
                          asin={asin}
                          domain={preferenceData?.target_domain}
                          mainData={mainData}
                        />
                      ) : (
                        <></>
                      )}
                      {isTab === "ai" ? (
                        <AdditionalInfos additionalData={additionalData} />
                      ) : (
                        <></>
                      )}
                      {isTab === "kd" ? (
                        <KeepaData keepaData={keepaData} />
                      ) : (
                        <></>
                      )}

                      <AsinFetcher
                        setAsinFetcher={setAsinFetcher}
                        isAsinFetcher={isAsinFetcher}
                      />
                    </div>

                    {/* <div className="product__cards_info">
                  <div className="cards_info_item gg">
                    <img src="/assets/img/icons8-warehouse-488.png"></img>
                    <div className="cards_info_actns">
                      <h4>Seller's Stock Fetcher</h4>
                      <button className="button-stroke button-small activity__button js-activity-button cc link">
                        <span>
                          Show
                          <FeatherIcon icon={"plus"} />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="cards_info_item pp">
                    <img src="/assets/img/icons8-product-488.png"></img>
                    <div className="cards_info_actns">
                      <h4>Additional Product Infos</h4>
                      <button className="button-stroke button-small activity__button js-activity-button cc link">
                        <span>
                          Show
                          <FeatherIcon icon={"plus"} />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="cards_info_item bb">
                    <img src="/assets/img/icons8-history-488.png"></img>
                    <div className="cards_info_actns">
                      <h4>Historical Data (Keepa)</h4>
                      <button className="button-stroke button-small activity__button js-activity-button cc link">
                        <span>
                          Show
                          <FeatherIcon icon={"plus"} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="oppess_card">
                    <FeatherIcon
                      icon={"alert-circle"}
                      className={"alertt__iccn"}
                    />
                    <h4>{translate("Sorry, Daily Limit Reached!")}</h4>
                    <p>
                      {translate(
                        "Your Daily Usage Limit Has Been Reached! Please wait until the next day to use it again.."
                      )}
                    </p>
                    <div className="flexx_btnns">
                      <NavLink
                        to="/asin360"
                        className="up_btn token_bt cursorp"
                      >
                        <span>{translate("Asin 360°")}</span>
                        <FeatherIcon icon={"arrow-up-right"} />
                      </NavLink>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PBIPage;
