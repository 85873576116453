import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import HistoryTable from "./_history_table";

import translate from "../../../shared/utils/translations";
import { requestHandler } from "../../../shared/utils/helpers";

const History = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });
  const [history, setHistory] = useState([]);

  const getHistoryAsins = async () => {
    await requestHandler("get", "/asin/history", null, true, null, null).then(
      (res) => {
        if (res.error) {
          toast.error(res.error);
          return [];
        }
        console.log("res", res?.data);
        setHistory(res?.data);
      }
    );
  };

  useEffect(() => {
    getHistoryAsins();
  }, []);

  const [isItem, setItem] = useState("Custom item #1");
  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">{translate("History")}</h2>

              <div className="keys__info">
                {translate(
                  "You can see all the products you have previously viewed here."
                )}
              </div>
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__info"></div>
                <div className="keys__list">
                  <div className="keys__item ss">
                    {/* <div className="activity__top ss">
                      <div className="nav">
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Custom item #1" ? " active" : " "
                          }`}
                          onClick={() => setItem("Custom item #1")}
                        >
                          Custom item #1
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Custom item #2" ? " active" : " "
                          }`}
                          onClick={() => setItem("Custom item #2")}
                        >
                          Custom item #2
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Custom item #3" ? " active" : " "
                          }`}
                          onClick={() => setItem("Custom item #3")}
                        >
                          Custom item #3
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Custom item #4" ? " active" : " "
                          }`}
                          onClick={() => setItem("Custom item #4")}
                        >
                          Custom item #4
                        </button>
                      </div>

                      <div className="right_items">
                        <form className="activity__form">
                          <input
                            className="activity__input"
                            type="text"
                            name="search"
                            placeholder={translate("Search")}
                            required=""
                          />
                          <button className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </button>
                        </form>
                        <div className="onex_ts__select tablet-show">
                          <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Filter item #1</option>
                              <option>Filter item #2</option>
                              <option>Filter item #3</option>
                            </select>
                          </div>
                        </div>
                        <div className="onex_ts__select">
                          <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Filter item #1</option>
                              <option>Filter item #2</option>
                              <option>Filter item #3</option>
                            </select>
                          </div>
                        </div>
                        <div className="activity__item activity__item_calendar js-activity-item ">
                          <button className="button-stroke button-small activity__button js-activity-button">
                            <span>Export</span>
                          </button>
                        </div>
                      </div>
                    </div> */}

                    <HistoryTable data={history} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
