import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Input from "../../common/form_elements/input";
import { forgotPassword, forgotPasswordSubmit } from "../../shared/utils/auth";
import { emailChecker, passwordChecker } from "../../shared/utils/helpers";
import UserContext from "../../shared/utils/userContext";
import translate from "../../shared/utils/translations";
import FeatherIcon from "feather-icons-react";
import { Helmet } from "react-helmet";

const Forgot = ({ setPage }) => {
  useEffect(() => {
    setPage("auth");
  });
  let navigate = useNavigate();
  const loggedInUser = useContext(UserContext);
  useEffect(() => {
    console.log(loggedInUser);
    if (loggedInUser) {
      if (loggedInUser?.role === "admin") {
        navigate("/admin-dashboard");
      } else {
        navigate("/client-dashboard");
      }
    }
  }, [loggedInUser]);
  const [formData, setFormData] = useState({});
  const [state, setState] = useState("forgot");
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);

  const [isBtnLoading, setBtnLoading] = useState(false);
  const submit = async () => {
    setBtnLoading(true);
    const checkEmailResult = emailChecker(formData.email);
    const checkPasswordResult = passwordChecker(formData?.password, formData?.password);
    if (checkEmailResult !== true) {
      setErrorMessage(checkEmailResult);
      setBtnLoading(false);
      return;
    }
    if (state === "forgot") {
      const user = await forgotPassword(formData.email);
      console.log(user);
      if (user) {
        setState("verify");
        setMessage(translate("Please check your email for verification code!"));
        setBtnLoading(false);
      } else {
        setErrorMessage(translate("Something went wrong, please try again!"));
        setBtnLoading(false);
      }
    } else if (state === "verify") {
      if (!formData.verify || !formData.password) {
        setErrorMessage(translate("Please fill all fields!"));
        setBtnLoading(false);
        return;
      } else if (checkPasswordResult !== true) {
        setErrorMessage(checkPasswordResult);
        setBtnLoading(false);
        return;
      } else {
        const user = forgotPasswordSubmit(formData.email, formData.verify, formData.password);

        if (user) {
          setMessage(translate("Password changed successfully!"));
          setBtnLoading(false);
          navigate("/login");
        } else {
          setErrorMessage(translate("Something went wrong, please try again!"));
          setBtnLoading(false);
        }
      }
    }
  };

  const onChange = (e, name) => {
    setFormData({ ...formData, [name]: e });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const [isShowPass, setShowPass] = useState(false);

  return (
    <>
    <Helmet>
      <title>{translate("Forgot Password")}</title>
    </Helmet>
      <div className="outer__inner auth">
        <div className="login">
          <div className="login__col bgb d_none"></div>
          <div className="login__col">
            <NavLink className="login__logo" to="/home">
              <img src="/assets/img/logo-dark4.png" alt="" />
              <img src="/assets/img/logo-dark4.png" alt="" />
            </NavLink>
            <div className="login__wrap">
              <div className="entry">
                <div className="entry__top">
                  <h3 className="entry__title h3">{translate("Forgot password")}</h3>
                  <div className="entry__info">
                    {translate("For security purposes, no withdrawals are permitted for 24 hours after password changed.")}
                  </div>
                </div>
                <div className="entry__form">
                  <div className="entry__tabs js-tabs">
                    <div className="entry__container">
                      <div className="entry__item js-tabs-item d_block">
                        <Input
                          id="email"
                          name="email"
                          label={translate("Email")}
                          type="email"
                          placeholder={translate("Email address")}
                          cusstomClass="field_icon"
                          hasIcon="icon-email"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    {state === "verify" && (
                      <>
                        <div className="entry__container">
                          <div className="entry__item js-tabs-item d_block">
                            <Input
                              id="verify"
                              name="verify"
                              label={translate("Verify code")}
                              type="verify"
                              placeholder={translate("Verify code")}
                              cusstomClass="field_icon"
                              hasIcon="icon-password"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="entry__container">
                          <div className="entry__item js-tabs-item d_block">
                            <div className="field field_view">
                              <div className="field__label">{translate("New password")}</div>
                              <div className="field__wrap">
                                <input
                                  id="password"
                                  className="field__input"
                                  type={isShowPass ? "text" : "password"}
                                  name="password"
                                  placeholder={translate("New password")}
                                  onChange={(e) => onChange(e.target.value, "password")}
                                  required=""
                                />
                                <button className="field__view" onClick={() => setShowPass(!isShowPass)}>
                                  <FeatherIcon icon={isShowPass ? "eye-off" : "eye"} width="20px" height="20px" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {errorMessage && <div className="entry__error">{errorMessage}</div>}
                    {message && <div className="entry__message">{message}</div>}
                    <a className="button entry__button cursorp" onClick={submit}>
                      {translate("Submit")} {isBtnLoading && <span className="btn-spinner"></span>}
                    </a>
                    <div className="login__foot">
                      <NavLink className="login__link" to="/login">
                        {" "}
                        {translate("Nevermind, I got it")}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            alt="auth-world-screen"
            src="https://partners.poweredbypercent.com/13c4f10f45b00276.png"
            className="AuthBackground_image__IINQD"
          ></img>
        </div>
      </div>
    </>
  );
};

export default Forgot;
