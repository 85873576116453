import React, { useEffect } from "react";
import { Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Select from "../../../common/form_elements/select";
import Input from "../../../common/form_elements/input";
import SelectWithOptions from "../../../common/form_elements/select_with_modified_options";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";

const NewUserDrawer = ({
  isNewDrawer,
  setNewDrawer,
  selectedUser,
  refresh,
  sendTokenToUser,
  sendAsinToken,
  resetUserTokens,
}) => {
  console.log(selectedUser);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [affiliates, setAffiliates] = useState([]);
  const getAffiliates = () => {
    requestHandler("get", "/admin/affiliates", null, true, null, "/login").then(
      (res) => {
        console.log(res);
        setAffiliates(res?.data?.affiliates);
      }
    );
  };

  useEffect(() => {
    console.log("selectedUser", selectedUser);
    if (selectedUser?.id) {
      getAffiliates();
      setFormData(selectedUser);
    }
  }, [selectedUser]);

  const submit = () => {
    setBtnLoading(true);
    requestHandler(
      "put",
      `/admin/user/${formData?.id}`,
      formData,
      true,
      null,
      "/login"
    ).then((res) => {
      console.log(res);
      setNewDrawer(false);
      setBtnLoading(false);
      refresh();
    });
  };

  const onChange = (e, name) => {
    console.log("onchange", e, name);
    setFormData({ ...formData, [name]: e });
  };

  return (
    <>
      <Drawer
        title={translate("User Form")}
        placement="right"
        onClose={() => setNewDrawer(false)}
        open={isNewDrawer}
        width={"40%"}
      >
        <>
          <div className="question__form">
            <div className="question__fieldset cvb">
              <SelectWithOptions
                label={translate("User Role")}
                options={[
                  { value: 1, text: translate("Free") },
                  { value: 2, text: translate("Paid 1") },
                  {
                    value: 3,
                    text: translate("Paid 2"),
                  },
                  {
                    value: 4,
                    text: translate("Paid 3"),
                  },
                  {
                    value: 10,
                    text: translate("Admin"),
                  },
                ]}
                customClass="mt_40"
                onChange={onChange}
                value={formData?.role_id}
                name="role_id"
              />
              <SelectWithOptions
                label="Status"
                options={[
                  { value: true, text: translate("Enabled") },
                  { value: false, text: translate("Disabled") },
                ]}
                customClass="mt_40"
                onChange={onChange}
                value={formData?.enabled}
                name="enabled"
              />
              <SelectWithOptions
                label="Affiliates"
                options={affiliates?.map((i) => {
                  return { value: i?.id, text: i?.affiliate_code };
                })}
                customClass="mt_40"
                onChange={onChange}
                value={formData?.affiliate_id}
                name="affiliate_id"
              />

              <Input
                id="f_name"
                name="first_name"
                label={translate("First Name")}
                type="text"
                placeholder={translate("First Name")}
                customClass="mt_40"
                onChange={onChange}
                value={formData?.first_name}
              />

              <Input
                id="l_name"
                name="last_name"
                label={translate("Last Name")}
                type="text"
                placeholder={translate("Last Name")}
                customClass="mt_40"
                onChange={onChange}
                value={formData?.last_name}
              />

              <Input
                id="phone"
                name="phone_number"
                label={translate("Phone")}
                type="text"
                placeholder={translate("Phone")}
                customClass="mt_40"
                onChange={onChange}
                value={formData?.phone_number}
              />

              <Input
                id="address"
                name="address"
                label={translate("Address")}
                type="text"
                placeholder={translate("Address")}
                customClass="mt_40"
                onChange={onChange}
                value={formData?.address}
              />
            </div>
            <div className="inf__art">
              <p className="ffv">
                <span>Product Based Info Tokens:</span>
                <span>Comparison Tokens:</span>
              </p>
              <p className="ffc">
                <strong>{formData?.asin_count}</strong>
                <strong>{formData?.token_count}</strong>
              </p>
            </div>
            <div className="question__btns">
              <div className="text___btns">
                <button
                  className="button-small question__button app"
                  onClick={() => {
                    sendAsinToken(selectedUser?.id);
                  }}
                >
                  {translate("Send asin Token")}
                </button>
                <button
                  className="button-small question__button app"
                  onClick={() => {
                    sendTokenToUser(selectedUser?.id);
                  }}
                >
                  {translate("Send comparison Token")}
                </button>
                <button
                  className="button-small question__button app tt"
                  onClick={() => {
                    resetUserTokens(selectedUser?.id);
                  }}
                >
                  {translate("Reset user tokens")}
                </button>
              </div>

              <button
                className="button-small question__button app"
                onClick={submit}
              >
                {translate("Save")}
                {isBtnLoading && <span className="btn-spinner"></span>}
              </button>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default NewUserDrawer;
