import React, { useEffect, useLayoutEffect, useState } from "react";
import { Drawer, Tooltip } from "antd";
import Input from "../../../common/form_elements/input";
import Select from "../../../common/form_elements/select";
import translate from "../../utils/translations";
import FeatherIcon from "feather-icons-react";
import { notify, requestHandler } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import CustomShippingSettingForm from "./custom_shipping_form";

const SettingForm = ({
  isSettingForm,
  setSettingForm,
  getMarketOptions,
  isBtnLoading,
  setBtnLoading,
  selectedPreference,
  user,
  refresh,
  isSettingEditable,
  customWeightMap,
  shippingInputs,
  setTargetCurrency,
  targetCurrency,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [customShippingMapData, setCustomShippingMapData] = useState([]);
  const [customDrawerVisible, setCustomDrawerVisible] = useState(false);
  const [customWeightType, setCustomWeightType] = useState();
  const [customCurrency, setCustomCurrency] = useState();
  const [customData, setCustomData] = useState([]);
  const [selectedShippingOption, setSelectedShippingOption] =
    useState("default_shipping");
  const onChange = (value, name) => {
    console.log("name", name, "value", value);

    setFormData({ ...formData, [name]: value });
  };
  const otherOnChange = (e) => {
    let { name, value } = e.target;
    console.log("name", name, "value", value, typeof value);
    if (name === "base_domain") {
      if (value === formData?.target_domain) {
        console.log("Base Market and Target Market can't be same");
        notify("Base Market and Target Market can't be same", "error");
        return;
      }
    }
    if (name === "target_domain") {
      if (value === formData?.base_domain) {
        console.log("Base Market and Target Market can't be same");

        notify("Base Market and Target Market can't be same", "error");
        return;
      } else {
        console.log(
          "value",
          value,
          getMarketOptions(user).find((i) => i.domain === value).currency
        );
        setTargetCurrency(
          getMarketOptions(user).find((i) => i.domain === value).currency
        );
      }
    }
    if (
      name === "include_discounts" ||
      name === "uses_keepa" ||
      name === "is_fba"
    ) {
      value = value === "true" ? true : false;
    }
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    setFormData(selectedPreference);
    console.log("selectedPreference", selectedPreference);
  }, [selectedPreference]);
  const updatePreference = () => {
    setBtnLoading(true);

    requestHandler(
      formData?.id === "new" ? "post" : "put",
      formData?.id === "new"
        ? `/preferences`
        : `/preferences/${selectedPreference?.id}`,
      { ...formData, customData },
      true,
      navigate,
      "/home"
    )
      .then((res) => {
        setBtnLoading(false);
        console.log("preferences", res?.data?.preferences);
        setFormData({});
        setCustomData([]);
        refresh();
      })
      .catch((err) => {
        console.log("err", err);
        setBtnLoading(false);
      });
  };
  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  useEffect(() => {
    console.log(
      "selectedShippingOption",
      selectedShippingOption,
      customWeightMap,
      customWeightType
    );
    if (
      selectedShippingOption === "custom_shipping" &&
      customWeightMap.length > 0
    ) {
      setFormData({
        ...formData,
        shipping_type: "customWeightMap",
        customWeightMap: shippingInputs?.map((i, index) => {
          return { weight: index, price: i, currency: targetCurrency };
        }),
        customWeightType,
      });
    }
  }, [selectedShippingOption, shippingInputs, customWeightType]);

  const doesOverlap = (newItem, currentIndex) => {
    return customData.some((item, index) => {
      if (index === currentIndex) return false; // Skip checking against itself
      // Check for actual overlapping ranges
      return (
        newItem.weight_max > item.weight_min &&
        newItem.weight_min < item.weight_max
      );
    });
  };

  const isValidRange = (item) => {
    // Check if min is not greater than max
    return (
      item.weight_min !== "" &&
      item.weight_max !== "" &&
      parseFloat(item.weight_min) <= parseFloat(item.weight_max)
    );
  };

  const handleInputChange = (index, field, value) => {
    console.log("index", index, "field", field, "value", value);
    let newCustomData = [...customData];
    newCustomData[index][field] = value;
    newCustomData[index].currency = customCurrency;
    newCustomData[index].weight_type = customWeightType;
    setCustomData([...newCustomData]);
  };

  const handleAdd = (min, max, price) => {
    const newItem = {
      weight_min: parseFloat(min),
      weight_max: parseFloat(max),
      price: parseFloat(price),
      currency: customCurrency,
      weight_type: customWeightType,
    };

    console.log("newItem", newItem);
    console.log(
      "isValidRange",
      isValidRange(newItem),
      "doesOverlap",
      doesOverlap(newItem),
      "customCurrency",
      customCurrency,
      "customWeightType",
      customWeightType,
      "min",
      min,
      min == ""
    );
    if (!isValidRange(newItem)) {
      return "Invalid Range";
    } else if (doesOverlap(newItem)) {
      return "Overlapping Range";
    } else if (!customCurrency) {
      return "Select Currency";
    } else if (!customWeightType) {
      return "Select Weight Type";
    } else if (min === "" || max === "" || price === "") {
      return "Fill All Fields";
    } else {
      setCustomData([...customData, newItem]);
      return true;
    }
  };

  const handleDelete = (index) => {
    const newCustomData = customData.filter((_, i) => i !== index);
    setCustomData(newCustomData);
  };
  const saveCustom = () => {
    setCustomDrawerVisible(false);
  };
  useEffect(() => {
    console.log(customData);
  }, [customData]);
  useEffect(() => {
    setCustomData(
      [...customData].map((i) => {
        return {
          ...i,
          currency: customCurrency,
          weight_type: customWeightType,
        };
      })
    );
  }, [customWeightType, customCurrency]);

  const handleEmptyCustomData = () => {
    setFormData({
      ...formData,
      shipping_type: "fixed",
    });
  };
  return (
    <>
      <Drawer
        title={translate("Manage Setting")}
        placement="right"
        onClose={() => setSettingForm(false)}
        open={isSettingForm}
        width={"50%"}
        className="payment__-drw tkns"
      >
        <CustomShippingSettingForm
          customDrawerVisible={customDrawerVisible}
          setCustomDrawerVisible={setCustomDrawerVisible}
          isSettingEditable={isSettingEditable}
          customWeightType={customWeightType}
          setCustomWeightType={setCustomWeightType}
          customCurrency={customCurrency}
          setCustomCurrency={setCustomCurrency}
          isBtnLoading={isBtnLoading}
          customData={customData}
          setCustomData={setCustomData}
          saveCustom={saveCustom}
          handleInputChange={handleInputChange}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          onEmptyCustomData={handleEmptyCustomData}
        />
        <>
          <div
            className={`buy_tokens_detials ${
              isSettingEditable && " not__editable"
            }`}
          >
            {isSettingEditable && (
              <div className="warn__card hhn">
                <FeatherIcon icon={"alert-circle"} />
                {translate(
                  "The saved setting cannot be edited! Add new settings if necessary."
                )}
              </div>
            )}
            <div className="mang_setting_area">
              <div className="setting__section">
                <div className="setting__category tbg">
                  {translate("General Settings")}
                </div>
                <div className="setting__fieldset">
                  <div className="setting__row bggh">
                    <Input
                      id="settingname_id"
                      name="name"
                      label={translate("Setting Name")}
                      type="text"
                      placeholder={translate("Custom setting etc.")}
                      onChange={onChange}
                      value={formData?.name}
                    />
                  </div>
                  <div className="setting__row">
                    <Select
                      label={translate("Base Market")}
                      options={getMarketOptions(user).map((i) => i.domain)}
                      onChange={otherOnChange}
                      name="base_domain"
                      value={`${formData?.base_domain}`}
                    />
                    <Select
                      label={translate("Target Market")}
                      options={getMarketOptions(user).map((i) => i.domain)}
                      onChange={otherOnChange}
                      name="target_domain"
                      value={`${formData?.target_domain}`}
                    />
                  </div>
                </div>

                <div className="setting__category tbg upt">
                  {translate("Shipping Pricing Settings")}
                  <p>
                    {translate(
                      "Select the shipping pricing settings you want to use for your products."
                    )}
                  </p>

                  <div className="warn__card">
                    <FeatherIcon icon={"alert-circle"} />
                    {translate("In the currency of the target market!")}
                  </div>
                </div>

                <div className="setting__fieldset">
                  <div className="radio__boxt">
                    <label className={`checkbox s_check rd`} for="shipping1">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="shipping_type"
                        id="shipping1"
                        checked={formData?.shipping_type === "fixed"}
                        onChange={otherOnChange}
                        value="fixed"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "Apply a constant shipping rate for each product, irrespective of its weight or size. Specify the fixed shipping cost in your preferred currency for each item."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("Fixed Shipping Rate")}{" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <input
                        className="field__input"
                        type="number"
                        placeholder={translate("10 etc.")}
                        name="fixed_deal"
                        onChange={otherOnChange}
                        value={formData?.fixed_deal}
                      />
                    </label>
                    <label className={`checkbox s_check rd`} for="shipping2">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="shipping_type"
                        id="shipping2"
                        checked={formData?.shipping_type === "weight"}
                        onChange={otherOnChange}
                        value="weight"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "Calculate the shipping cost based on the weight of the product. Enter a rate in your preferred currency per kilogram. This rate encompasses the cost of labeling each product."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("Weight-Based Shipping")}{" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <input
                        className="field__input"
                        type="number"
                        placeholder={translate("10 etc.")}
                        name="shipping_deal"
                        onChange={otherOnChange}
                        value={formData?.shipping_deal}
                      />
                    </label>
                    <label className={`checkbox s_check rd`} for="shipping3">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="shipping_type"
                        id="shipping3"
                        onChange={otherOnChange}
                        checked={formData?.shipping_type === "weightLabel"}
                        value="weightLabel"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "Determine shipping cost based on product weight and include a distinct fee for labeling. Provide two rates: a weight-based shipping rate in your preferred currency per kilogram and an additional fixed labeling fee per item. This option offers a detailed cost breakdown, separating weight handling charges from labeling costs."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("Weight-Based + Labeling")}{" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <div className="flex___inpts">
                        <input
                          className="field__input"
                          type="number"
                          placeholder={translate("10 etc.")}
                          name="shipping_deal"
                          onChange={otherOnChange}
                          value={formData?.shipping_deal}
                        />
                        <input
                          className="field__input"
                          type="number"
                          placeholder={translate("10 etc.")}
                          name="labeling_deal"
                          onChange={otherOnChange}
                          value={formData?.labeling_deal}
                        />
                      </div>
                    </label>

                    <div
                      className={`custoom_shpp_Area ${
                        formData?.is_fba ? " not__aallow" : " "
                      }`}
                    >
                      <label className={`checkbox s_check rd`} for="shipping4">
                        <input
                          className="checkbox__input"
                          type="radio"
                          name="shipping_type"
                          id="shipping4"
                          value="custom_shipping"
                          checked={
                            formData?.shipping_type === "customWeightMap"
                          }
                          onChange={() => {
                            setCustomDrawerVisible(true);
                            setFormData({
                              ...formData,
                              shipping_type: "customWeightMap",
                            });
                            setSelectedShippingOption("custom_shipping");
                          }}
                        />
                        <span className="checkbox__inner">
                          <svg className="icon icon-plus">
                            <use xlinkHref="#icon-plus"></use>
                          </svg>
                          <svg className="icon icon-check">
                            <use xlinkHref="#icon-check"></use>
                          </svg>

                          <Tooltip
                            title={translate(
                              "You can make more specific settings such as 10 USD if it is between 1KG and 5KG or 15USD if it is between 5KG and 8KG."
                            )}
                            placement="top"
                          >
                            <span className="checkbox__text">
                              {translate("Custom Shipping Settings")}
                              <FeatherIcon icon={"help-circle"} />
                            </span>
                          </Tooltip>
                        </span>
                        <img
                          className="cst__icnn"
                          src="/assets/img/custom-notification.svg"
                        />
                      </label>
                      {formData?.shipping_type === "customWeightMap" ? (
                        <Tooltip
                          title={translate("Edit Custom Settings")}
                          placement="right"
                        >
                          <div className="edt_cstm_shippng">
                            <button
                              onClick={() =>
                                setCustomDrawerVisible(!customDrawerVisible)
                              }
                              className="button-stroke button-small activity__button js-activity-button cc link"
                            >
                              <span>
                                <FeatherIcon icon={"edit"} />
                              </span>
                            </button>
                          </div>
                        </Tooltip>
                      ) : (
                        <></>
                      )}

                      {formData?.is_fba ? (
                        <div className="fbaam__warn">
                          <FeatherIcon icon={"alert-circle"} />
                          {translate(
                            "For Custom Shipping Setting, you must select the FBM option!"
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {formData?.shipping_type === "customWeightMap" ? (
                      <div className="custom_shipping_infos">
                        <h5>{translate("Saved Custom Shipping Setting —")}</h5>
                        {selectedPreference?.weightMap?.map((i) => {
                          return (
                            <p>
                              {i.min_weight_kg} - {i.max_weight_kg} KG :{" "}
                              {i.price} {i.currency}
                            </p>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="setting__category tbg upt">
                  {translate("FBA/FBM Setting")}

                  <p>
                    {translate(
                      "Switching the Fulfillment Type for Amazon Listings (FBA/FBM)"
                    )}
                  </p>
                </div>

                <div className="setting__fieldset">
                  <div className="radio__boxt rrt">
                    <div
                      className={`fba__Select_Area ${
                        formData?.shipping_type === "customWeightMap"
                          ? " not__aallow"
                          : " "
                      }`}
                    >
                      <label className={`checkbox s_check rd`} for="fba">
                        <input
                          className="checkbox__input"
                          type="radio"
                          name="is_fba"
                          id="fba"
                          onChange={otherOnChange}
                          checked={formData?.is_fba}
                          value={true}
                        />

                        <span className="checkbox__inner">
                          <svg className="icon icon-plus">
                            <use xlinkHref="#icon-plus"></use>
                          </svg>
                          <svg className="icon icon-check">
                            <use xlinkHref="#icon-check"></use>
                          </svg>
                          <span className="checkbox__text">
                            {translate("FBA (Fullfilled by Amazon)")}
                          </span>
                        </span>
                      </label>
                      {formData?.shipping_type === "customWeightMap" ? (
                        <div className="fbaam__warn">
                          <FeatherIcon icon={"alert-circle"} />
                          {translate(
                            "If Custom Shipping Setting is active, you can't select FBA!"
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <label className={`checkbox s_check rd`} for="fbm">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="is_fba"
                        id="fbm"
                        checked={!formData?.is_fba}
                        onChange={otherOnChange}
                        value={false}
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">
                          {translate("FBM (Fulfilled by Merchant)")}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>

                {/* erdem gy sonra yapilacak
                import - export fee setting */}

                <div className="setting__category tbg upt">
                  {translate("Import - Export Fees")}
                  {/* <p>
                    {translate(
                      "Select the shipping pricing settings you want to use for your products."
                    )}
                  </p> */}

                  <div className="warn__card">
                    <FeatherIcon icon={"alert-circle"} />
                    {translate("In the currency of the target market!")}
                  </div>
                </div>
                {/* 
                <div className="setting__fieldset">
                  <div className="setting__row bggh">
                    <Input
                      id="import_id"
                      name="number"
                      label={translate("Import Fee")}
                      type="text"
                      placeholder="0"
                    />
                    <Input
                      id="export_id"
                      name="number"
                      label={translate("Export Fee")}
                      type="text"
                      placeholder="0"
                    />
                  </div>
                </div> */}

                <div className="setting__category tbg upt">
                  {translate("Show discounted prices?")}

                  <p>
                    {translate(
                      "Do you want to see discounted prices for buyer side?"
                    )}
                  </p>
                </div>
                <div className="setting__fieldset">
                  <div className="radio__boxt rrt">
                    <label
                      className={`checkbox s_check rd`}
                      for="show-discount"
                    >
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="include_discounts"
                        id="show-discount"
                        checked={formData?.include_discounts}
                        onChange={otherOnChange}
                        value={true}
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">
                          {translate("Show")}
                        </span>
                      </span>
                    </label>
                    <label
                      className={`checkbox s_check rd`}
                      for="hide-discount"
                    >
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="include_discounts"
                        id="hide-discount"
                        checked={!formData?.include_discounts}
                        onChange={otherOnChange}
                        value={false}
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">
                          {translate("Hide")}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className="setting__category tbg upt">
                  {translate("Show Keepa Data?")}

                  <p>
                    {translate(
                      "Do you want to include historical data by Keepa in Product Based Info?"
                    )}
                  </p>
                </div>
                <div className="setting__fieldset">
                  <div className="radio__boxt rrt">
                    <label className={`checkbox s_check rd`} for="show-keepa">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="uses_keepa"
                        id="show-keepa"
                        checked={formData?.uses_keepa}
                        onChange={otherOnChange}
                        value={true}
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">
                          {translate("Show")}
                        </span>
                      </span>
                    </label>
                    <label className={`checkbox s_check rd`} for="hide-keepa">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="uses_keepa"
                        id="hide-keepa"
                        checked={!formData?.uses_keepa}
                        onChange={otherOnChange}
                        value={false}
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">
                          {translate("Hide")}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="setting__category tbg upt">
                  {translate("VAT Settings")}

                  <p>
                    {translate(
                      "Please select correct VAT settings for your seller account."
                    )}
                  </p>
                </div>
                <div className="setting__fieldset">
                  <div className="radio__boxt">
                    <label className={`checkbox s_check rd`} for="vat1">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="vat_type"
                        onChange={otherOnChange}
                        checked={formData?.vat_type === "noVat"}
                        id="vat1"
                        value="noVat"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "If you’re a UK seller and you’re not registered for VAT then you need to select the Non-VAT Registered scheme. As a non-registered VAT seller, you still have to pay VAT on the cost of the product and you have to pay 20% VAT on top of the Amazon fees(if you’re a UK-based company)"
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("Exempt from VAT (No VAT)")} (%){" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <strong>0%</strong>
                    </label>
                    <label className={`checkbox s_check rd`} for="vat2">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="vat_type"
                        onChange={otherOnChange}
                        checked={formData?.vat_type === "vatOnProfit"}
                        id="vat2"
                        value="vatOnProfit"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "When sending goods to a US fulfillment center, you don’t have to pay sales tax in the US. However, as soon as you ship inventory to Europe (for example to a fulfillment center when using Fulfillment by Amazon, or FBA), you must pay that country’s import VAT."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("VAT on profit")} (%){" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <input
                        className="field__input"
                        type="number"
                        placeholder={translate("10 etc.")}
                        name="vat_percentage"
                        onChange={otherOnChange}
                        value={formData?.vat_percentage}
                      />
                    </label>
                    <label className={`checkbox s_check rd`} for="vat3">
                      <input
                        className="checkbox__input"
                        type="radio"
                        name="vat_type"
                        onChange={otherOnChange}
                        checked={formData?.vat_type === "vatOnSales"}
                        id="vat3"
                        value="vatOnSales"
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>

                        <Tooltip
                          title={translate(
                            "Unlike in the US, where sales tax is added to the list price, VAT is included in the list price in the EU. Let’s say that you sell a product on Amazon.com for $100. To sell it for the equivalent price in the UK, you would list it on Amazon.co.uk for $120 (assuming 20% VAT). This is important to remember when analyzing competitive pricing on Amazon’s European marketplaces."
                          )}
                          placement="top"
                        >
                          <span className="checkbox__text">
                            {translate("VAT on sales")} (%){" "}
                            <FeatherIcon icon={"help-circle"} />
                          </span>
                        </Tooltip>
                      </span>

                      <input
                        className="field__input"
                        type="number"
                        placeholder={translate("10 etc.")}
                        name="vat_percentage"
                        onChange={otherOnChange}
                        value={formData?.vat_percentage}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {isSettingEditable ? (
              <></>
            ) : (
              <div className="drw__btm_actns">
                <button
                  className="button setting__button"
                  onClick={() => {
                    updatePreference();
                    setSettingForm(false);
                  }}
                >
                  {translate("Save")}{" "}
                  {isBtnLoading && <span className="btn-spinner"></span>}
                </button>
              </div>
            )}
          </div>
        </>
      </Drawer>
    </>
  );
};

export default SettingForm;
