import React, { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Modal, Input, Dropdown, Menu, Button, Tooltip } from "antd";
import { FilterFilled } from "@ant-design/icons";
import translate from "../../../../shared/utils/translations";
import EmptyIcon from "../../../../../assets/img/empty.png";
import { Resizable } from "react-resizable";
import ReactDragListView from "react-drag-listview";
import NoImage from "../../../../../assets/img/no_image.png";

const ResizableTitle = (props) => {
  const { onResize, width, minWidth = 50, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      minConstraints={[minWidth, 0]}
      maxConstraints={[400, 0]}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => e.stopPropagation()}
        />
      }
    >
      <th {...restProps} />
    </Resizable>
  );
};

const ComparisonTable = ({
  isComparisonDrawer,
  setComparisonDrawer,
  setFilterValues,
  setDataSource,
  filterValues,
  dataSource,
  data,
  setBufferAsinListData,
  asinListData,
}) => {
  console.log(`${asinListData?.base_domain} - ${asinListData?.currency}`);
  let navigate = useNavigate();
  const [isTableSort, setTableSort] = useState(() => {
    const savedSort = localStorage.getItem("cmptableSort");
    return savedSort !== null ? savedSort : "list";
  });
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    localStorage.setItem("cmptableSort", isTableSort);
  }, [isTableSort]);

  const renderColumnTitle = (title, dataIndex) => (
    <div className="filter_title dragHandler">
      {title}{" "}
      <FilterFilled
        className={`filter_tb_button ${
          filterValues[dataIndex] ? "active" : ""
        }`}
        onClick={(event) => {
          event.stopPropagation();
          showFilterModal(dataIndex, title);
        }}
      />
    </div>
  );
  const makeReadable = (text) => {
    if (!text) {
      return "";
    }

    return text
      .split("_")
      .map((word) => {
        let words = word.split(/(?=[A-Z])/);
        return words
          .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
          .join(" ");
      })
      .join(" ");
  };

  useEffect(() => {
    setColumns([
      {
        title: <span className="dragHandler">{translate("Image")}</span>,
        dataIndex: "image_url",
        key: "image_url",
        width: 150,
        minWidth: 100,
        render: (item) => (
          <>
            <div className="column_info">{translate("Image")}</div>
            <img className="table__immg" src={item || NoImage}></img>
          </>
        ),
      },
      {
        title: <span className="dragHandler">Asin</span>,
        dataIndex: "asin",
        key: "asin",
        width: 150,
        minWidth: 100,
        render: (item) => (
          <>
            <div className="column_info">Asin</div>
            {item}
          </>
        ),
      },
      {
        title: <span className="dragHandler">{translate("Title")}</span>,
        dataIndex: "title",
        key: "title",
        width: 200,
        minWidth: 100,
        render: (item, row) => (
          <>
            <div className="column_info">{translate("Title")}</div>
            <a className="table__lnk" target="_blank" href={`https://${asinListData?.target_domain}/dp/${
        row?.asin
      }`}>
              {item}
            </a>
          </>
        ),
      },
      {
        title: renderColumnTitle(
          `${asinListData?.base_domain} - ${asinListData?.currency}`,
          "base_domain_converted_price"
        ),
        dataIndex: "base_domain_converted_price",
        key: "base_domain_converted_price",
        width: 200,
        minWidth: 150,
        render: (item) => (
          <>
            <div className="column_info">www.amazon.com - (USD)</div>
            {item}
          </>
        ),
      },
      {
        title: renderColumnTitle(
          `${asinListData?.target_domain} - ${asinListData?.currency}`,
          "target_domain_price"
        ),
        dataIndex: "target_domain_price",
        key: "target_domain_price",
        width: 200,
        minWidth: 150,
        render: (item) => (
          <>
            <div className="column_info">www.amazon.ca - (USD)</div>
            {item}
          </>
        ),
      },
      {
        title: renderColumnTitle(translate("Inbound Cost"), "shipping_cost"),
        dataIndex: "shipping_cost",
        key: "shipping_cost",
        width: 200,
        minWidth: 150,
        sorter: (a, b) => a.shipping_cost - b.shipping_cost,
        render: (item) => (
          <>
            <div className="column_info">{translate("Inbound Cost")}</div>
            {item}
          </>
        ),
      },
      {
        title: renderColumnTitle(translate("Fees"), "fee"),
        dataIndex: "fee",
        key: "fee",
        width: 100,
        minWidth: 80,
        sorter: (a, b) => a.fee - b.fee,
        render: (item) => (
          <>
            <div className="column_info">{translate("Fees")}</div>
            {item}
          </>
        ),
      },
      {
        title: renderColumnTitle("Roi", "roi"),
        dataIndex: "roi",
        key: "roi",
        width: 100,
        minWidth: 80,
        sorter: (a, b) => a.roi - b.roi,
        className: "roi_class",
        render: (item) => (
          <>
            <div className="column_info">Roi</div>
            <span
              className={`roi__tag 
            ${
              item >= 40
                ? " kp_green"
                : item < 40 && item >= 15
                ? " kp_green_soft"
                : item < 15 && item >= 5
                ? " kp_blue"
                : item < 5 && item >= 0
                ? " kp_orange"
                : item < 0
                ? " kp_red"
                : " "
            }
            `}
            >
              {item}%
            </span>
          </>
        ),
      },
      {
        title: renderColumnTitle(
          translate("Profit Per Item"),
          "profit_per_item"
        ),
        dataIndex: "profit_per_item",
        key: "profit_per_item",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.profit_per_item - b.profit_per_item,
        render: (item) => (
          <>
            <div className="column_info">{translate("Profit Per Item")}</div>
            <span
              className={`prft__tag 
            ${
              item > 10
                ? " pr_green"
                : item <= 10 && item >= 0
                ? " pr_green_soft"
                : item < 0
                ? " pr_red"
                : " "
            }
            `}
            >
              {item}
            </span>
          </>
        ),
      },
      {
        title: renderColumnTitle(translate("Sales Rank"), "sales_rank"),
        dataIndex: "sales_rank",
        key: "sales_rank",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.sales_rank - b.sales_rank,
        render: (item) => (
          <>
            <div className="column_info">{translate("Sales Rank")}</div>
            {item}
          </>
        ),
      },
      {
        title: (
          <span className="dragHandler">
            {translate("Sales Rank Category")}
          </span>
        ),
        dataIndex: "sales_rank_category",
        key: "sales_rank_category",
        width: 200,
        minWidth: 100,
        render: (item) => (
          <>
            <div className="column_info">
              {translate("Sales Rank Category")}
            </div>
            {makeReadable(item)}
          </>
        ),
      },
      {
        title: (
          <span className="dragHandler">{translate("Has Discount?")}</span>
        ),
        dataIndex: "has_discount",
        key: "has_discount",
        width: 200,
        minWidth: 100,
        sorter: (a, b) =>
          a.has_discount === b.has_discount ? 0 : a.has_discount ? -1 : 1,
        filters: [
          {
            text: "True",
            value: true,
          },
          {
            text: "False",
            value: false,
          },
        ],
        onFilter: (value, record) => record.has_discount === value,
        render: (item) => (
          <>
            <div className="column_info">{translate("Has Discount?")}</div>
            <span className={`table__tag ${item ? "is_true" : "is_false"}`}>
              {item ? "True" : "False"}
            </span>
          </>
        ),
      },
      {
        title: <span className="dragHandler">{translate("Hazmat?")}</span>,
        dataIndex: "hazmat",
        key: "hazmat",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => (a.hazmat === b.hazmat ? 0 : a.hazmat ? -1 : 1),
        filters: [
          {
            text: "True",
            value: true,
          },
          {
            text: "False",
            value: false,
          },
        ],
        onFilter: (value, record) => record.hazmat === value,
        render: (item) => (
          <>
            <div className="column_info">{translate("Hazmat?")}</div>
            <span className={`table__tag ${item ? "is_true" : "is_false"}`}>
              {item ? "True" : "False"}
            </span>
          </>
        ),
      },
      {
        title: translate("Total Offer Count"),
        dataIndex: "total_offer_count",
        key: "total_offer_count",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.total_offer_count - b.total_offer_count,
        render: (item, record) => (
          <>
            <div className="column_info">{translate("Total Offer Count")}</div>
            {record.fba_seller_count + record.fbm_seller_count}
          </>
        ),
      },
      {
        title: renderColumnTitle(
          translate("FBA Seller Count"),
          "fba_seller_count"
        ),
        dataIndex: "fba_seller_count",
        key: "fba_seller_count",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.fba_seller_count - b.fba_seller_count,
        render: (item) => (
          <>
            <div className="column_info">{translate("FBA Seller Count")}</div>
            {item}
          </>
        ),
      },
      {
        title: renderColumnTitle(
          translate("FBM Seller Count"),
          "fbm_seller_count"
        ),
        dataIndex: "fbm_seller_count",
        key: "fbm_seller_count",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.fbm_seller_count - b.fbm_seller_count,
        render: (item) => (
          <>
            <div className="column_info">{translate("FBM Seller Count")}</div>
            {item}
          </>
        ),
      },
      {
        title: (
          <span className="dragHandler">{translate("Amazon Seller?")}</span>
        ),
        dataIndex: "amazon_seller",
        key: "amazon_seller",
        width: 200,
        minWidth: 100,
        sorter: (a, b) =>
          a.amazon_seller === b.amazon_seller ? 0 : a.amazon_seller ? -1 : 1,
        filters: [
          {
            text: "True",
            value: true,
          },
          {
            text: "False",
            value: false,
          },
        ],
        onFilter: (value, record) => record.amazon_seller === value,
        render: (item) => (
          <>
            <div className="column_info">{translate("Amazon Seller?")}</div>
            <span className={`table__tag ${item ? "is_true" : "is_false"}`}>
              {item ? "True" : "False"}
            </span>
          </>
        ),
      },
      {
        title: <span className="dragHandler">{translate("Brand")}</span>,
        dataIndex: "brand",
        key: "brand",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.brand - b.brand,
        render: (item) => (
          <>
            <div className="column_info">{translate("Brand")}</div>
            {item}
          </>
        ),
      },
      {
        title: <span className="dragHandler">{translate("Binding")}</span>,
        dataIndex: "binding",
        key: "binding",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.binding - b.binding,
        render: (item) => (
          <>
            <div className="column_info">{translate("Binding")}</div>
            {item}
          </>
        ),
      },
      {
        title: <span className="dragHandler">{translate("Product Type")}</span>,
        dataIndex: "product_type_name",
        key: "product_type_name",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.product_type_name - b.product_type_name,
        render: (item) => (
          <>
            <div className="column_info">{translate("Product Type")}</div>
            {item}
          </>
        ),
      },
      {
        title: (
          <span className="dragHandler">{translate("Item Weight")} (KG)</span>
        ),
        dataIndex: "item_weight",
        key: "item_weight",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.item_weight - b.item_weight,
        render: (item) => (
          <>
            <div className="column_info">{translate("Item Weight")} (KG)</div>
            {item}
          </>
        ),
      },
      {
        title: (
          <span className="dragHandler">{translate("Item Measures")}</span>
        ),
        dataIndex: "item_dimensions",
        key: "item_dimensions",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.item_dimensions - b.item_dimensions,
        render: (item) => (
          <>
            <div className="column_info">{translate("Item Measures")}</div>
            {item}
          </>
        ),
      },
      {
        title: (
          <span className="dragHandler">{translate("Package Measures")}</span>
        ),
        dataIndex: "package_dimensions",
        key: "package_dimensions",
        width: 200,
        minWidth: 100,
        sorter: (a, b) => a.package_dimensions - b.package_dimensions,
        render: (item) => (
          <>
            <div className="column_info">{translate("Package Measures")}</div>
            {item}
          </>
        ),
      },
      {
        title: translate("Action"),
        key: "action",
        fixed: "right",
        width: 100,
        minWidth: 80,
        className: "table_actin",
        render: (text, record) => (
          <>
            <div className="column_info">{translate("Details")}</div>

            <Tooltip
              title={translate("Show Product Based Info")}
              placement="left"
            >
              <NavLink
                className={"pbi__link table_icon"}
                to={`/product-based-info/${record.asin}`}
                target="_blank"
              >
               <FeatherIcon icon={"package"} />
              </NavLink>
            </Tooltip>
          </>
        ),
      },
    ]);
  }, [asinListData]);

  useEffect(() => {
    const resizeableColumns = columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        minWidth: column.minWidth,
        onResize: handleResize(index),
      }),
    }));
    setColumns(resizeableColumns);
  }, [data]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (fromIndex !== toIndex) {
        const newColumns = [...columns];
        const item = newColumns.splice(fromIndex, 1)[0];
        newColumns.splice(toIndex, 0, item);
        setColumns(newColumns);
      }
    },
    nodeSelector: "th",
    handleSelector: ".dragHandler",
    ignoreSelector: "react-resizable-handle",
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      setColumns((currentColumns) => {
        const nextColumns = [...currentColumns];
        nextColumns[index] = { ...nextColumns[index], width: size.width };
        return nextColumns;
      });
    };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFilterColumn, setCurrentFilterColumn] = useState("");
  const [currentFilterTitle, setCurrentFilterTitle] = useState("");
  const [currentFilterValues, setCurrentFilterValues] = useState({
    min: "",
    max: "",
  });

  const showFilterModal = (dataIndex, title) => {
    setCurrentFilterColumn(dataIndex);
    setCurrentFilterTitle(title);
    const currentValues = filterValues[dataIndex] || { min: "", max: "" };
    setCurrentFilterValues(currentValues);
    setIsModalVisible(true);
  };

  const handleMinValueChange = (e) => {
    setCurrentFilterValues((current) => ({ ...current, min: e.target.value }));
  };

  const handleMaxValueChange = (e) => {
    setCurrentFilterValues((current) => ({ ...current, max: e.target.value }));
  };

  const applyFilters = () => {
    const updatedFilterValues = {
      ...filterValues,
      [currentFilterColumn]: { ...currentFilterValues },
    };

    console.log("updatedFilterValues", updatedFilterValues);
    setFilterValues(updatedFilterValues);

    const filteredData = data.filter((item) => {
      return Object.keys(updatedFilterValues).every((column) => {
        const filter = updatedFilterValues[column];
        const value = item[column];
        const minPass = filter.min !== "" ? value >= Number(filter.min) : true;
        const maxPass = filter.max !== "" ? value <= Number(filter.max) : true;
        return minPass && maxPass;
      });
    });

    console.log("filteredData", filteredData);
    setBufferAsinListData(filteredData);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);

    const resetFilterValues = { ...filterValues };
    if (resetFilterValues[currentFilterColumn]) {
      delete resetFilterValues[currentFilterColumn];
    }

    setFilterValues(resetFilterValues);

    const filteredData = filterDataByValues(data, resetFilterValues);
    setBufferAsinListData(filteredData);
  };

  function filterDataByValues(data, filterValues) {
    return data.filter((item) => {
      return Object.keys(filterValues).every((key) => {
        const { min, max } = filterValues[key];
        const value = item[key];
        const minCondition = min !== undefined ? value >= Number(min) : true;
        const maxCondition = max !== undefined ? value <= Number(max) : true;
        return minCondition && maxCondition;
      });
    });
  }

  return (
    <>
      <div
        className={`dif__ablee ${isTableSort === "list" ? " " : " block_m"}`}
      >
        <div className="merged___area">
          <div className="table__sortn">
            <span
              onClick={() => setTableSort("list")}
              className={`${isTableSort === "list" ? "active" : ""}`}
            >
              <FeatherIcon icon={"list"} />
            </span>
            <span
              onClick={() => setTableSort("grid")}
              className={`${isTableSort === "grid" ? "active" : ""}`}
            >
              <FeatherIcon icon={"grid"} />
            </span>
          </div>
          <Modal
            title={`${translate("Filter by")} ${currentFilterTitle}`}
            className="tble_fltr"
            visible={isModalVisible}
            onOk={applyFilters}
            onCancel={handleCancel}
            okText={translate("Apply")}
            cancelText={translate("Reset")}
            footer={[
              <Button key="back" onClick={handleCancel} className="cancel__btn">
                {translate("Reset")}
              </Button>,
              <Button key="submit" type="primary" onClick={applyFilters}>
                {translate("Apply")}
              </Button>,
            ]}
          >
            <Input
              placeholder={translate("Minimum value")}
              value={currentFilterValues.min}
              onChange={handleMinValueChange}
              type="number"
            />
            <Input
              placeholder={translate("Maximum value")}
              value={currentFilterValues.max}
              onChange={handleMaxValueChange}
              style={{ marginTop: "10px" }}
              type="number"
            />
          </Modal>
        </div>
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            columns={columns}
            dataSource={dataSource}
            components={{
              header: {
                cell: ResizableTitle,
              },
            }}
            pagination={{
              locale: {
                items_per_page: translate("Page"),
              },
              position: ["topRight", "bottomRight"],
            }}
            locale={{
              emptyText: (
                <span className="empty__tble">
                  <img src={EmptyIcon} />
                </span>
              ),
            }}
            className="cmp__table has_pag_btn rest_table"
            loading={{
              spinning: !data || data.length === 0,
              indicator: <div className="bt-spinner"></div>,
            }}
          />
        </ReactDragListView.DragColumn>
      </div>
    </>
  );
};

export default ComparisonTable;
