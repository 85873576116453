import React from "react";
import { Tooltip } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import {
  copyToClipboard,
  makeReadable,
} from "../../../../shared/utils/helpers";

const MainInfos = ({
  mainData,
  keepaData,
  additionalData,
  targetOffersData,
  imageUrl,
  noImg,
}) => {
  return (
    <>
      <div className="product__main_info">
        <div className="leftt__box">
          <img src={imageUrl || noImg}></img>
        </div>
        <div className="right__box">
          <div className="pr_title">
            <h4>{additionalData?.title}</h4>
            <Tooltip title={translate("Copy")} placement="top">
              <button
                className="icconc__btn"
                onClick={() => copyToClipboard(additionalData?.title)}
              >
                <FeatherIcon icon={"copy"} />
              </button>
            </Tooltip>
          </div>
          <div className="btmm_infos">
            <div className="catehgory__area">
              {makeReadable(targetOffersData?.salesRankCategory)}
            </div>
          </div>

          <div className="extra__tagss">
            {keepaData?.buyBoxRatio30?.[0]?.ratio &&
              parseFloat(keepaData?.buyBoxRatio30?.[0]?.ratio) > 50.0 && (
                <Tooltip
                  title={translate(
                    "There is High Competition for This Product!"
                  )}
                  placement="top"
                >
                  <div className="tagg__iitm">
                    <img
                      className="trend__up hhcct"
                      src="/assets/img/highc__icn.svg"
                    />
                    {translate("High Competition!")}
                  </div>
                </Tooltip>
              )}
            {mainData?.isAmazonIsSeller && (
              <Tooltip
                title={translate(
                  "One of the Sellers of This Product is Amazon!"
                )}
                placement="top"
              >
                <div className="tagg__iitm">
                  <img
                    className="trend__up aammz"
                    src="/assets/img/amzz_icn.svg"
                  />
                  {translate("Amazon Sells too!")}
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainInfos;
